@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;

/*====================================================================

lv2.css

=====================================================================*/

@use "_components/breadcrumb"; //パンくずリスト
@use "_lv2/free01"; //会社情報
@use "_lv2/company"; //会社情報
@use "_lv2/recruit"; //採用情報
@use "_lv2/works"; //制作実績
@use "_lv2/contact"; //お問い合わせ
@use "_lv2/news"; //ブログ
@use "_lv2/blog"; //ブログ
@use "_lv2/aside"; //サイドバー

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	overflow: hidden;
	background-color: rgba($color: $l-gray, $alpha: 0.7);
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			height: 300px;
		}
		@include media-breakpoint-up(lg) {
			height: 30vw;
		}
		@include media-breakpoint-up(xl) {
			height: 500px;
		}
		@include media-breakpoint-up(full) {
			height: floor-decimal(calc(480 / 1920), 4) * 100vw;
		}
		@include media-breakpoint-down(sm) {
			height: 230px;
		}
	}
	&__bg {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(xl) {
		}
		@include media-breakpoint-up(full) {
		}
		&__img {
			width: 100%;
			height: 100%;
			position: relative;
			@include bg_filter($black,0.6,before);
			&__inner {
				width: 100%;
				height: 100%;
			}
		}
	}
	& .l-cont {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
	}
}

.main-view {
	&__tit {
		@include f-w(400);
		@include line-h(1.4);
		position: relative;
		width: 100%;
		padding-top: clamp(2em, 4vw, 5rem);
		& > em {
			display: block;
			margin-top: 0.5em;
			font-style: normal;
			@include f-s_xxs(1, 10);
			@include f-w(500);
			@include f-family(font02);
			@include l-sp(0.1em);
			color: $main_c;
			@include media-breakpoint-up(xxl) {
				@include f-size(24);
			}
			&.is-404 {
				@include f-family(font02);
				@include f-w(500);
				@include f-s_xxs(2, 32);
				@include l-sp(0.02em);
				color: $main_c;
				@include media-breakpoint-up(xl) {
					@include f-size(64);
				}
			}
		}
		& > span {
			display: block;
			@include f-s_xxs(1.4, 20);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include l-sp(0.075em);
				@include f-size(40);
			}
			&.is-404 {
				@include f-family(font02);
				@include f-w(500);
				@include f-s_xxs(2.5, 32);
				@include l-sp(0.02em);
				color: $txt_c;
				@include media-breakpoint-up(xl) {
					@include f-size(72);
				}
			}
			&.is-category {
				display: inline-block;
				@include f-w(700);
				@include f-s_xxs(1, 16);
				margin-top: 0.1em;
				color: $txt_c;
				@include l-sp(0);
				@include media-breakpoint-up(xl) {
					@include f-size(28);
				}
				& b {
					@include f-w(700);
					display: inline-block;
					content: "";
					vertical-align: middle;
					width: 0.6em;
					height: 0.1em;
					margin: 0 0.5em 0.2em;
					background: $txt_c;
					@include media-breakpoint-up(xl) {
						@include f-size(36);
					}
				}
				span {
					display: inline-block;
					color: $main_c;
					@include media-breakpoint-up(xl) {
						@include f-size(36);
					}
					em {
						padding-right: 1em;
						font-style: normal;
					}
				}
			}
			&.is-archive {
				@include f-w(700);
				@include f-s_xxs(1.25, 20);
				margin-top: 0.3em;
				color: $txt_c;
				@include media-breakpoint-up(xl) {
					@include f-size(40);
				}
			}
		}
	}
	.cl--white {
		span,em {
			color: $white
		}
		.breadcrumb {
			&__list {
				li {
					color: $white;
					a {
						color: $white;
					}
				}
			}
		}
	}
}

// シングルページ用
.main-view {
	&__txt {
		@include flex-column;
		row-gap: clamp(1em, 1.5vw, 1.5rem);
		padding-top: 1rem;
		@include media-breakpoint-up(lg) {
			padding-top: clamp(2em, 3.5vw, 3.5rem);
		}
		&__sub {
			@include f-w(400);
			@include line-h(1.4);
			position: relative;
			width: 100%;
			em {
				@include f-family(font02);
				padding-left: 0.4em;
				font-style: normal;
				@include f-s_xxs(.8, 8);
				@include f-w(500);
				color: $gray;
				@include media-breakpoint-up(xl) {
					@include f-size(16);
				}
			}
			span {
				@include f-s_xxs(0.9, 9);
				@include f-w(700);
				color: $main_c;
				@include media-breakpoint-up(xl) {
					@include f-size(20);
				}
			}
		}
		&__tit {
			@include f-w(700);
			@include line-h(1.4);
			position: relative;
			width: 100%;
			@include f-s_xxs(1.1, 20);
			@include media-breakpoint-up(xxl) {
				@include f-size(34);
			}
		}
		&__category {
			li {
				display: inline-block;
				margin-right: 0.2em;
			}
			.category-item {
				@include media-breakpoint-down(md) {
					font-size: 0.75em;
				}
			}
		}
	}
}

.contents {
	padding-top: clamp(4rem, 6vw, 6rem);
}

// 一覧ページが4列並びの場合
.common-column4 {
	@include flex-column;
	row-gap: clamp(3rem, 5vw, 5rem);
}

// 4列並びの場合のカテゴリー
.common-category {
	@include media-breakpoint-up(lg) {
		display: flex;
		column-gap: 3%;
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			width: clamp(130px, 10%, 144px);
			@include f-s_lg(1.14, 4);
			flex-shrink: 0;
			padding-top: 0.2em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		@include media-breakpoint-down(md) {
			padding: 0.3rem 0 0.5rem;
			margin-bottom: 0.5rem;
			border-bottom: 1px solid $m-gray;
			@include f-w(500);
			font-size: 1.25em;
			@include f-family(font02);
		}
	}
	&__list {
		display: flex;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			gap: 0.75em 0.5em;
			flex-shrink: 1;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
		li {
			@include media-breakpoint-down(md) {
				border-bottom: 1px solid $l-gray;
			}
			a {
				color: $txt_c;
				@include dec-none;
				display: flex;
				column-gap: 0.5em;
				position: relative;
				@include transition;
				@include media-breakpoint-up(lg) {
					padding: 0.4em 1.25em 0.4em 1em;
					border: 1px solid $m-gray;
					@include radius(999px);
				}
				@include media-breakpoint-down(md) {
					padding: 0.75em 0.5em 0.75em 0.2em;
				}
				&::before {
					@include media-breakpoint-down(md) {
						content: "";
						position: absolute;
						left: 0;
						bottom: -1px;
						width: 0;
						height: 1px;
						@include transition;
						background: $main_c;
					}
				}
				&:hover {
					@include media-breakpoint-up(lg) {
						border-color: $hover_c;
						color: $hover_c;
					}
					&::before {
						@include media-breakpoint-down(md) {
							width: 100%;
						}
					}
				}
				.is-circle {
					padding-top: 0.5em;
					font-size: 0.7em;
					text-decoration: none !important;
				}
				.is-text {
					@include f-w(500);
				}
			}
		}
	}
}

// common-flex
$common-main-w-lg: 72%; //メイン横幅%
$common-aside-w-lg: 25%; //アサイド横幅%
$common-main-w-xl: 76.16%; //メイン横幅%
$common-aside-w-xl: 19.83%; //アサイド横幅%

.common-flex {
	width: 100%;
	position: relative;
	display: flex;
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		row-gap: clamp(3rem, 5vw, 5rem);
	}
	&.is-right {
		@include media-breakpoint-up(lg) {
			flex-direction: row-reverse;
		}
	}
	&__main {
		@include media-breakpoint-up(lg) {
			width: $common-main-w-lg;
		}
		@include media-breakpoint-up(xl) {
			width: $common-main-w-xl;
		}
	}
	&__aside {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: $common-aside-w-lg;
		}
		@include media-breakpoint-up(xl) {
			width: $common-aside-w-xl;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

.common-flex__list {
	display: flex;
	&.is-col1 {
		flex-direction: column;
		row-gap: clamp(1.5rem, 3vw, 3rem);
		@include media-breakpoint-up(sm) {
			row-gap: clamp(1.5rem, 2vw, 2rem);
		}
	}
	&.is-col2 {
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			column-gap: 3%;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		@include media-breakpoint-up(md) {
			column-gap: 2%;
		}
		@include media-breakpoint-up(lg) {
			column-gap: 4.5%;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(1.5rem, 3vw, 3rem);
		}
		& > * {
			@include media-breakpoint-up(sm) {
				flex-basis: 48.5%;
			}
			@include media-breakpoint-up(lg) {
				flex-basis: 47.75%;
			}
		}
	}
	&.is-col3 {
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			column-gap: 3%;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		@include media-breakpoint-up(md) {
			column-gap: 2%;
		}
		@include media-breakpoint-up(lg) {
			column-gap: 1.25%;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 2.8%;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(1.5rem, 3vw, 3rem);
		}
		& > * {
			@include media-breakpoint-up(sm) {
				flex-basis: 48.5%;
			}
			@include media-breakpoint-up(md) {
				flex-basis: 32%;
			}
			@include media-breakpoint-up(lg) {
				flex-basis: 32.5%;
			}
			@include media-breakpoint-up(xl) {
				flex-basis: 31.46%;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	404 page
/--------------------------------------------------------------------*/
.cont-404 {
	&__inner {
		background: $white;
		border: 1px solid $m-gray;
		padding: clamp(2rem, 4vw, 4rem) 5%;
	}
	&__tit {
		@include f-s_xxs(1.25, 10);
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		text-align: center;
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
		span {
			position: relative;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
	&__txt {
		margin-bottom: 4rem;
		@include clearfix;
		@include media-breakpoint-up(md) {
			margin-bottom: 6rem;
		}
	}
	&__thumbnail {
		float: right;
		width: 50%;
		margin-left: 6%;
		margin-bottom: 3rem;
	}
	&__foot {
		padding-top: clamp(3rem, 5vw, 5rem);
	}
}
.single-cont__tag {
	margin-bottom: 1em;
	a {
		font-size: 0.94em;
		color: $txt_c;
		padding-left: 1.5rem;
		margin-right: 0.5rem;
		padding-bottom: 0.3em;
		position: relative;
		@include icon(tag, before);
		@include dec-none;
		@include transition;
		&::before {
			position: absolute;
			top: 0.5em;
			left: 0.2rem;
			font-size: 0.875em;
			color: $gray;
			@include dec-none;
			@include transition;
		}
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 0;
			height: 1px;
			background: $main_c;
			@include transition;
		}
		&:hover {
			color: $main_c;
			&::before {
				color: $main_c;
			}
			&::after {
				width: 100%;
			}
		}
	}
}

.addtoany_shortcode {
	padding-bottom: 1.5em;
}

.single-sub {
	margin-bottom: 1.5rem;
	@include media-breakpoint-up(sm) {
		margin-bottom: 2rem;
		display: flex;
		align-items: flex-start;
	}
}

.single-date {
	@include f-family(font01);
	line-height: 1.4;
	color: $gray;
	margin-right: 1rem;
}

/* align */
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.alignright {
	float: right;
	margin-left: 1.5em;
}
.alignleft {
	float: left;
	margin-right: 1.5em;
}
