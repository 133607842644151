@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/

.blog-intro {
	padding: clamp(1.5rem, 2vw, 2rem) 5%;
	margin-bottom: clamp(3rem, 6vw, 6rem);
	background: $p-gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(2rem, 3vw, 3rem) 8%;
		text-align: center;
	}
}

.contents-flex {
	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: space-between;
	}
}

.contents-left {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: calc(96% - 320px);
	}
}

// blog-main
.blog-mainimg {
	margin-bottom: clamp(1.5rem,2vw,2rem);
	&__inner {
		
	}
	& figcaption {
		line-height: 1.4;
		margin-top: .75em;
		font-size: 0.9em;
	}
}

// blog-pdf
.blog-pdf {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__list {
		@include flex-column;
		row-gap: clamp(0.5em, 1vw, 1em);
		& li {
			a {
				padding: 1.25em 4% 1.25em 4.5em;
				@include transition;
				border: 1px solid $m-gray;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				@include line-h(1.4);
				color: $txt_c;
				@include dec-none;
				@include f-w(500);
				@include radius(999px);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.25em;
					top: 0.8em;
					font-size: 1.6em;
					color: $m-gray;
				}
				&:hover {
					background: $gray;
					border-color: $gray;
					color: $white;
				}
			}
		}
	}
}

/* blog-video */
.blog-video {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 1fr 37.5%;
			grid-template-rows: auto 1fr;
			column-gap: 10%;
			row-gap: clamp(1rem, 1.5vw, 2rem);
		}
	}
	&__tit {
		@include f-w(700);
		@include line-h(1.4);
		@include f-s_xxs(1.14, 16);
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			@include f-s_lg(1.5, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		@include media-breakpoint-down(md) {
			margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
		}
	}
	&__video {
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
	}
}

/* blog-table */
.blog-table {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__inner {
	}
	&__tit {
		text-align: center;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include f-w(700);
		@include f-s_xxs(1.25, 8);
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
	&__table {
		margin-bottom: 2rem;
		width: 100%;
		@include line-h(1.5);
		& tr {
			border-bottom: 1px solid $m-gray;
			&:first-child {
				border-top: 1px solid $m-gray;
			}
		}
		& th {
			text-align: left;
			@include f-w(700);
			padding: 1rem 0;
			@include media-breakpoint-up(lg) {
				padding: 1.25rem 0;
				width: 25%;
				min-width: 180px;
			}
			@include media-breakpoint-up(xl) {
				width: 225px;
			}
			@include media-breakpoint-down(md) {
				display: block;
				padding-bottom: 0;
			}
		}
		& td {
			@include media-breakpoint-up(lg) {
				padding: 1.25rem 1.25rem 1.25rem 0.5rem;
			}
			@include media-breakpoint-down(md) {
				padding: 1rem 0;
				display: block;
				padding-top: 0;
				padding-left: 0;
			}
		}
	}
}
