@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	recruit
/--------------------------------------------------------------------*/
// recruit-merit
.recruit-merit {
	position: relative;
	width: 100%;
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&::before {
		position: absolute;
		top: 0;
		right: 0;
		content: "";
		width: clamp(160px, 38.54vw, 740px);
		height: clamp(160px, 38.54vw, 740px);
		background: $bg_c3;
		z-index: z-index(module, part01);
		display: block;
	}
	.l-cont_full {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		position: relative;
	}
}

.recruit-merit {
	&__tit {
		position: relative;
		padding-left: 10%;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		&__inner {
			@include f-w(700);
			small {
				display: block;
				@include f-s_xxs(1, 16);
				@include l-sp(0.02em);
				padding-left: 0.5em;
				@include media-breakpoint-up(xl) {
					@include f-size(36);
				}
			}
			strong {
				display: block;
				@include f-w(700);
				@include f-s_xxs(2.5, 40);
				@include line-h(1);
				position: relative;
				@include media-breakpoint-up(xl) {
					@include f-size(80);
				}
				b {
					color: $main_c;
					font-size: 1.5em;
				}
				&::after {
					position: absolute;
					top: calc(50% + 2px);
					right: calc(100% + 0.2em);
					content: "";
					width: 100%;
					height: 2px;
					background: $main_c;
				}
			}
		}
	}
}

.recruit-merit {
	&__txt {
		padding-left: 10%;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		&__inner {
			width: 90%;
			@include media-breakpoint-up(md) {
				width: 50%;
			}
		}
	}
	&__list {
		display: flex;
		counter-reset: merit-num;
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			column-gap: 4.64%;
			& > * {
				&:nth-of-type(even) {
					@include media-breakpoint-up(md) {
						margin-top: 9vw;
					}
					@include media-breakpoint-up(xl) {
						margin-top: 134px;
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 4vw, 4rem);
		}
	}
}

.recruit-merit__box {
	@include media-breakpoint-up(md) {
		width: 47.68%;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	&__inner {
		position: relative;
	}
	&__img {
		position: relative;
		z-index: z-index(module, part01);
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 94%;
		}
		@include media-breakpoint-up(lg) {
			width: 87.37%;
		}
		&__inner {
			@include aspect-img(16, 9);
			margin: 0;
		}
	}
	&__txt {
		@include flex-end;
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			margin-top: -3rem;
		}
		@include media-breakpoint-up(lg) {
			margin-top: -4rem;
		}
		@include media-breakpoint-down(sm) {
			margin-top: -2rem;
		}
		&__inner {
			width: 100%;
			background: $white;
			padding: 0 5% clamp(1.5rem, 3vw, 3rem);
			position: relative;
			@include media-breakpoint-up(md) {
				width: 92%;
			}
			@include media-breakpoint-up(xl) {
				width: 82.52%;
				padding: 0 10% clamp(1.5rem, 3vw, 3rem);
			}
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 1px;
				height: 2em;
				background: $main_c;
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 2em;
				height: 1px;
				background: $main_c;
			}
		}
	}
	&__tit {
		@include f-w(700);
		position: relative;
		margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
		span {
			padding: clamp(1.5rem, 2vw, 2rem) 5% 0.5em 4rem;
			display: block;
			@include f-s_xxs(1.14, 14);
			@include media-breakpoint-up(sm) {
				padding: clamp(1.5rem, 2vw, 2rem) 5% 0.5em 12%;
			}
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 12);
				padding: 2rem 3% 0.5em 14%;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
				padding: 2rem 5% 0.5em 4.5rem;
			}
			&::after {
				content: "";
				position: absolute;
				left: 1.25em;
				top: -1em;
				width: clamp(4rem, 7vw, 7rem);
				width: 1px;
				height: 3.5em;
				transform-origin: bottom left;
				transform: rotate(40deg);
				background: $main_c;
				@include media-breakpoint-up(md) {
					top: -1.5em;
					height: calc(3.5rem + 2em);
					left: 0.2em;
				}
				@include media-breakpoint-up(xl) {
					top: -2em;
					left: 0.2em;
					height: calc(4rem + 3em);
				}
			}
		}
		&::before {
			position: absolute;
			left: 0;
			bottom: calc(100% - 0.4em);
			@include line-h(1);
			counter-increment: merit-num;
			content: counter(merit-num, decimal-leading-zero);
			@include f-family(font02);
			@include f-w(400);
			@include l-sp(0);
			color: $main_c;
			@include f-s_xxs(3, 32);
			@include media-breakpoint-up(md) {
				@include f-s_md(2.5, 30);
				left: -0.2em;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(80);
				left: -0.3em;
			}
		}
	}
}

/*.recruit-remarks */
.recruit-remarks {
	position: relative;
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&__inner {
		@include flex-column;
		row-gap: clamp(2rem, 3vw, 3rem);
	}
}

.recruit-remarks__box {
	border: 1px solid $m-gray;
	padding: clamp(1.5em, 2vw, 2rem) 5%;
	@include media-breakpoint-up(lg) {
		padding: clamp(2em, 3vw, 3rem) 7% clamp(2em, 3vw, 3rem);
	}
}

/*.recruit-introduction */
.recruit-introduction {
	margin-bottom: clamp(6rem, 9vw, 9rem);
	position: relative;
	&__list {
		@include flex-column;
		row-gap: clamp(3rem, 6vw, 6rem);
		& > * {
			&:nth-of-type(odd) {
				@include media-breakpoint-up(md) {
					.recruit-introduction__box {
						&::before {
							width: 80%;
							height: 100%;
						}
					}
					.recruit-introduction__box__img {
						&::before {
							left: 10%;
						}
					}
					.recruit-introduction__box__inner {
						flex-direction: row-reverse;
					}
					.recruit-introduction__box__txt {
						padding-left: map-get($contmargin, sp);
						@include flex-end;
					}
				}
			}
			&:nth-of-type(even) {
				@include media-breakpoint-up(md) {
					.recruit-introduction__box {
						&::before {
							height: 100%;
						}
					}
					.recruit-introduction__box__img {
						&::before {
							right: 10%;
						}
					}
					.recruit-introduction__box__txt {
						padding-right: map-get($contmargin, sp);
					}
				}
			}
		}
	}
}

// introduction テキスト幅
$recruit-introduction_w: 680;// px

.recruit-introduction__box {
	overflow: hidden;
	width: 100%;
	position: relative;
	&__inner {
		display: flex;
		width: 100%;
		@include media-breakpoint-up(md) {
			column-gap: 5%;
			align-items: center;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 5.18%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1.2em;
		}
	}
	&__img {
		position: relative;
		@include media-breakpoint-up(md) {
			padding-bottom: clamp(2rem, 5vw, 5rem);
			width: 45%;
		}
		@include media-breakpoint-up(xl) {
			width: 47.91%;
			height: 100%;
		}
		@include media-breakpoint-down(sm) {
			width: 90%;
			padding-bottom: 1.2rem;
		}
		&::before {
			background: $bg_c1;
			z-index: -1;
			position: absolute;
			bottom: 0;
			content: "";
			@include media-breakpoint-up(md) {
				height: 100%;
				width: 90%;
			}
			@include media-breakpoint-down(sm) {
				left: 10%;
				bottom: 0;
				content: "";
				width: 100vw;
				height: 90%;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 60%;
		}
		@include media-breakpoint-up(xl) {
			width: 46.91%;
		}
		@include media-breakpoint-down(lg) {
			padding-right: map-get($contmargin, sp);
		}
		@include media-breakpoint-down(sm) {
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
		&__inner {
			@include media-breakpoint-up(lg) {
				max-width: #{$recruit-introduction_w}px;
			}
			@include media-breakpoint-up(full) {
				max-width: floor-decimal(calc($recruit-introduction_w / 1920), 4) * 100vw;
			}
		}
	}
	&__tit {
		margin-bottom: clamp(1rem, 2vw, 2rem);
		@include f-w(700);
		@include line-h(1.4);
		@include f-s_xxs(1.14, 12);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.25, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(30);
		}
	}
}

/* recruit-gallery */
.recruit-gallery {
	overflow: hidden;
	padding-top: clamp(3rem, 6vw, 6rem);
	padding-bottom: clamp(3rem, 6vw, 6rem);
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&__tit {
		@include f-w(400);
		@include line-h(1.4);
		position: relative;
		margin-bottom: clamp(2rem, 5vw, 5rem);
		&__inner {
			position: relative;
			padding-right: 1em;
			height: 100%;
			&::after {
				content: "";
				position: absolute;
				top: 30%;
				left: 100%;
				width: 100vw;
				height: 2px;
				background: $main_c;
			}
		}
		b {
			@include f-family(font02);
			@include l-sp(0.03em);
			@include f-s_xxs(2.5, 46);
			@include f-w(400);
			padding-right: 0.2em;
			display: inline-block;
			@include media-breakpoint-up(xl) {
				@include f-size(96);
			}
		}
		small {
			@include f-s_xxs(0.85, 12);
			@include f-w(700);
			color: $main_c;
			display: inline-block;
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
}

.recruit-gallery__box {
	&__inner {
		@include aspect-img;
	}
	figcaption {
		margin-top: 0.5em;
		.is-column & {
			@include media-breakpoint-down(sm) {
				font-size: 0.71em;
			}
		}
	}
}

.recruit-gallery {
	position: relative;
	// swiper custom
	.swiper {
		width: 100%;
		overflow: visible;
		position: relative;
		padding-bottom: clamp(4rem, 8vw, 8rem);
	}
	.swiper-control {
		position: absolute;
		left: 0;
		bottom: 0;
		display: flex;
		column-gap: 1.5em;
		align-items: center;
		width: 100%;
		max-width: 1440px;
	}
	.swiper-next_prev {
		display: flex;
		height: 48px;
		flex-shrink: 0;
		width: 102px;
		column-gap: 6px;
		@include media-breakpoint-up(md) {
			height: 64px;
			column-gap: 8px;
			width: 136px;
		}
		@include media-breakpoint-up(lg) {
			height: 90px;
			width: 188px;
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		float: none;
		display: block;
		background-image: none;
		width: 48px;
		height: 48px;
		position: relative;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		@include media-breakpoint-up(md) {
			width: 64px;
			height: 64px;
		}
		@include media-breakpoint-up(lg) {
			width: 90px;
			height: 90px;
		}
		&::before {
			content: "";
			position: absolute;
			background: transparent;
			border: 1px solid $other_c1;
			@include transition;
			width: 100%;
			height: 100%;
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			color: $other_c1;
			font-size: 1.5em;
			@include transition;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include f-size(48);
			}
		}
		&:hover {
			&::before {
				background: $main_c;
				border-color: $main_c;
			}
			&::after {
				color: $white;
			}
		}
	}
	.swiper-button-prev {
		@include icon(arrow2_left, after);
		&::after {
			transform: translate(-60%, -50%);
		}
	}
	.swiper-button-next {
		@include icon(arrow2_right, after);
		&::after {
			transform: translate(-40%, -50%);
		}
	}
	.swiper-scrollbar {
		width: 100%;
		flex-shrink: 1;
		height: 6px;
		z-index: z-index(module, part01);
		background: $p-gray;
		@include media-breakpoint-up(md) {
			height: 8px;
		}
	}
	.swiper-scrollbar-drag {
		background: $m-gray;
		height: 6px;
		@include transition;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			height: 8px;
		}
		&:hover {
			background-color: $main_c;
		}
	}
}

/* recruit-table */
.recruit-table {
	margin-bottom: clamp(6rem, 12vw, 12rem);
	@include flex-column;
	row-gap: clamp(6rem, 9vw, 9rem);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__tit {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
		b {
			@include f-family(font02);
			color: $black;
			@include f-s_xxs(2, 24);
			@include f-w(400);
			position: relative;
			display: block;
			@include line-h(1);
			margin-bottom: 0.2em;
			@include media-breakpoint-up(xl) {
				@include f-size(54);
			}
		}
		small {
			@include f-family(font01);
			@include f-s_xxs(1, 12);
			color: $main_c;
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__table {
		@include media-breakpoint-up(lg) {
			width: 65.55%;
		}
	}
}

/* recruit-form */
.recruit-form {
	&__tit {
		text-align: center;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		b {
			display: block;
			@include line-h(1);
			@include f-family(font02);
			@include f-s_xxs(2, 28);
			margin-bottom: 0.2em;
			@include f-w(400);
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}
		small {
			@include f-family(font01);
			@include f-s_xxs(1, 12);
			color: $main_c;
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
}
