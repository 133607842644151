@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1753px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1540px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1752px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1539px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1539px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1540px) and (max-width: 1752px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  bottom: 20px;
  padding-left: 5%;
  right: 5%;
}
@media (min-width: 576px) {
  .breadcrumb {
    right: 3%;
  }
}
@media (min-width: 1540px) {
  .breadcrumb {
    right: 48px;
  }
}
@media (min-width: 1921px) {
  .breadcrumb {
    right: 5vw;
  }
}
.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.78em;
}
@media (min-width: 768px) {
  .breadcrumb__list {
    font-size: 0.85em;
  }
}
.breadcrumb__list .item-home {
  font-size: 1.1em;
}
.breadcrumb__list li {
  position: relative;
  color: #4B4D4E;
  letter-spacing: 0;
}
.breadcrumb__list li:not(:last-child) {
  padding-right: 1.5rem;
}
.breadcrumb__list li:not(:last-child):after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.breadcrumb__list li:not(:last-child)::after {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb__list a {
  color: #979DA4;
  transition: all 0.3s ease 0s;
  vertical-align: top;
}
.breadcrumb__list a:first-child {
  text-decoration: none !important;
}
.breadcrumb__list a:hover {
  color: #1A4069;
}

/*--------------------------------------------------------------------/
	free01
/--------------------------------------------------------------------*/
.free01-intro {
  margin-bottom: clamp(3rem, 5vw, 5rem);
}
.free01-intro__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .free01-intro__inner {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .free01-intro__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
.free01-intro__tit {
  position: relative;
}
.free01-intro__tit__inner {
  font-weight: 700;
  font-size: calc(1.2rem + 16 * (100vw - 280px) / 1360);
  letter-spacing: 0.02em;
}
@media (min-width: 1753px) {
  .free01-intro__tit__inner {
    font-size: 1.89rem;
  }
}
.free01-intro__tit__inner small {
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  letter-spacing: 0.02em;
  padding-left: 0.5em;
}
@media (min-width: 1540px) {
  .free01-intro__tit__inner small {
    font-size: 2rem;
  }
}
.free01-intro__tit__inner strong {
  display: block;
  font-weight: 700;
  font-size: calc(3rem + 32 * (100vw - 280px) / 1360);
  line-height: 1;
  position: relative;
}
@media (min-width: 1540px) {
  .free01-intro__tit__inner strong {
    font-size: 4.44rem;
  }
}
.free01-intro__tit__inner strong b {
  color: #1A4069;
  font-size: 1.5em;
}
.free01-intro__tit__inner strong::after {
  position: absolute;
  top: calc(50% + 2px);
  right: calc(100% + 0.2em);
  content: "";
  width: 100%;
  height: 2px;
  background: #1A4069;
}
.free01-intro__tit__inner::before {
  position: absolute;
  top: 0.7em;
  right: calc(100% + 1em);
  content: "";
  width: 100%;
  height: 1px;
  background-color: #1A4069;
}
@media (max-width: 1175px) {
  .free01-intro__tit__inner::before {
    right: calc(100% + 0.7em);
  }
}
@media (min-width: 1176px) {
  .free01-intro__txt {
    width: 63%;
  }
}
.free01-intro__txt .txt {
  font-size: calc(0.96rem + 4 * (100vw - 280px) / 1360);
  font-weight: 500;
}
@media (min-width: 1540px) {
  .free01-intro__txt .txt {
    font-size: 1.11rem;
  }
}

.free01-nav {
  margin-bottom: clamp(8rem, 14vw, 14rem);
}
.free01-nav__tit {
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  position: relative;
  padding-left: 1.5em;
  color: #000000;
}
@media (min-width: 1540px) {
  .free01-nav__tit {
    font-size: 1.33rem;
  }
}
.free01-nav__tit::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  width: 2em;
  height: 1px;
  background: #000000;
}
.free01-nav__list {
  display: flex;
  counter-reset: free01-navnum;
}
@media (min-width: 1176px) {
  .free01-nav__list {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1.5em;
  }
}
@media (max-width: 1175px) {
  .free01-nav__list {
    flex-direction: column;
    row-gap: 0.75em;
  }
}

@media (min-width: 1176px) {
  .free01-nav__item {
    width: 49%;
  }
}
@media (min-width: 1540px) {
  .free01-nav__item {
    width: 48.33%;
  }
}
.free01-nav__item a {
  display: block;
  transition: all 0.3s ease 0s;
  color: #000000;
  position: relative;
  border: 1px solid #6398BE;
  border-radius: 999px;
  text-decoration: none !important;
  padding: 1.25em 3.5rem 1.25em 2em;
}
@media (min-width: 1176px) {
  .free01-nav__item a {
    padding: 1.5em 3.5rem 1.5em 2em;
  }
}
.free01-nav__item a:hover {
  border-color: #6398BE;
  background-color: #1A4069;
  color: #ffffff;
}
.free01-nav__item a:hover .free01-nav__item__tit::before {
  color: #ffffff;
}
.free01-nav__item a:hover .free01-nav__item__arrow {
  background: #6398BE;
}
.free01-nav__item__tit {
  position: relative;
  font-weight: 500;
  /*&::before {
  	position: absolute;
  	top: 50%;
  	left: -7px;
  	color: $hover_c;
  	@include transition;
  	transform: translateY(-50%);
  	counter-increment: free01-navnum;
  	content: counter(free01-navnum, decimal-leading-zero);
  	@include line-h(1);
  	@include f-family(font02);
  	@include l-sp(0);
  	font-size: 1.75em;
  	@include f-w(500);
  }*/
}
@media (min-width: 1540px) {
  .free01-nav__item__tit {
    font-size: 1.11rem;
  }
}
.free01-nav__item__arrow {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  background: #6398BE;
}
.free01-nav__item__arrow i {
  position: relative;
  color: #ffffff;
  display: block;
  width: 100%;
  height: 100%;
}
.free01-nav__item__arrow i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.33rem;
}

.free01-list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(7rem, 14vw, 14rem);
  counter-reset: free01-num;
}

.free01-box {
  position: relative;
}
.free01-box::before {
  content: "";
  position: absolute;
}
@media (min-width: 768px) {
  .free01-box::before {
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 70%;
  }
}
@media (max-width: 767px) {
  .free01-box::before {
    height: 86%;
    width: 100%;
    top: 2rem;
    left: 0;
  }
}
.free01-box__inner {
  position: relative;
  display: flex;
  overflow: hidden;
  padding-bottom: 4em;
}
@media (min-width: 768px) {
  .free01-box__inner {
    column-gap: 4%;
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .free01-box__inner {
    flex-direction: column;
    row-gap: clamp(1.5rem, 2.5vw, 2rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
  }
}
.free01-box:nth-of-type(odd)::before {
  left: 0;
  background-color: #F4F1EC;
}
@media (min-width: 768px) {
  .free01-box:nth-of-type(odd) .free01-box__inner {
    padding-left: 8%;
  }
}
@media (min-width: 768px) {
  .free01-box:nth-of-type(odd) .free01-box__txt {
    justify-content: flex-end;
  }
}
.free01-box:nth-of-type(odd) .free01-box__img::before {
  background: #F4F1EC;
}
@media (min-width: 768px) {
  .free01-box:nth-of-type(odd) .free01-box__img::before {
    left: 2rem;
  }
}
@media (min-width: 1540px) {
  .free01-box:nth-of-type(odd) .free01-box__img::before {
    left: 3rem;
  }
}
.free01-box:nth-of-type(even)::before {
  right: 0;
  background-color: #EFF5FB;
}
@media (min-width: 768px) {
  .free01-box:nth-of-type(even) .free01-box__inner {
    flex-direction: row-reverse;
    padding-right: 8%;
  }
}
.free01-box:nth-of-type(even) .free01-box__img::before {
  background: #EFF5FB;
}
@media (min-width: 768px) {
  .free01-box:nth-of-type(even) .free01-box__img::before {
    right: 2rem;
  }
}
@media (min-width: 1540px) {
  .free01-box:nth-of-type(even) .free01-box__img::before {
    right: 3rem;
  }
}

.free01-box__txt {
  position: relative;
  z-index: 3;
}
@media (min-width: 768px) {
  .free01-box__txt {
    width: 45.5%;
    display: flex;
  }
}
@media (min-width: 1540px) {
  .free01-box__txt {
    padding-top: 2em;
    width: calc(52% - 100px);
  }
}
@media (max-width: 767px) {
  .free01-box__txt {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .free01-box__txt__inner {
    max-width: 640px;
  }
}
@media (min-width: 1540px) {
  .free01-box__txt__inner {
    width: 640px;
  }
}
.free01-box__txt__tit {
  overflow: hidden;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
  position: relative;
  /*&::before {
  	counter-increment: free01-num;
  	content: counter(free01-num, decimal-leading-zero);
  	@include f-family(font02);
  	color: $main_c;
  	position: absolute;
  	top: 0;
  	left: 0;
  	@include l-sp(0.01em);
  	@include f-w(400);
  	@include line-h(0.9);
  	@include f-s_xxs(1.75, 32);
  	@include media-breakpoint-up(xs) {
  		@include f-s_xs(2.25, 30);
  	}
  	@include media-breakpoint-up(md) {
  		@include f-s_md(2.25, 24);
  	}
  	@include media-breakpoint-up(xl) {
  		@include f-size(60);
  	}
  }*/
}
.free01-box__txt__tit span {
  font-weight: 700;
  font-size: calc(1.14rem + 18 * (100vw - 280px) / 1360);
  position: relative;
}
@media (min-width: 768px) {
  .free01-box__txt__tit span {
    font-size: calc(1.4rem + 20 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .free01-box__txt__tit span {
    font-size: 2rem;
  }
}
.free01-box__txt .btn-wrap {
  margin-top: 2.5rem;
}
@media (max-width: 767px) {
  .free01-box__txt .btn-wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

.free01-box__img {
  position: relative;
  z-index: 3;
  /*&::before {
  	content: "";
  	position: absolute;
  	z-index: -1;
  	@include media-breakpoint-up(md) {
  		height: 50%;
  		width: 100%;
  		bottom: 0;
  	}
  	@include media-breakpoint-down(sm) {
  		height: 85%;
  		width: 100vw;
  		left: 1.5rem;
  		bottom: 0;
  	}
  }*/
}
@media (min-width: 768px) {
  .free01-box__img {
    width: 48.75%;
    padding-bottom: clamp(3rem, 4vw, 4rem);
  }
}
@media (min-width: 1540px) {
  .free01-box__img {
    width: calc(48% - 24px);
    padding-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  .free01-box__img {
    width: 100%;
    padding-bottom: clamp(1.5rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .free01-box__img__inner {
    height: 100%;
  }
  .free01-box__img__inner > div, .free01-box__img__inner > a {
    height: 100%;
  }
}
@media (min-width: 1540px) {
  .free01-box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .free01-box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .free01-box__img__inner > div,
  .free01-box__img__inner figure,
  .free01-box__img__inner a,
  .free01-box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/
.company-message {
  margin-bottom: clamp(4rem, 12vw, 12rem);
  padding-top: clamp(2rem, 7vw, 7rem);
  padding-bottom: clamp(6rem, 8vw, 8rem);
  position: relative;
  background: #ffffff;
  overflow: hidden;
}
@media (max-width: 575px) {
  .company-message {
    padding-bottom: calc(4rem + 40vw);
  }
}
.company-message .l-cont {
  position: relative;
  z-index: 2;
}
.company-message__inner {
  position: relative;
}
@media (min-width: 1176px) {
  .company-message__inner {
    display: grid;
    grid-template-columns: 59.72% 30.55%;
    column-gap: 9.73%;
  }
}
@media (max-width: 1175px) {
  .company-message__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media (min-width: 1176px) {
  .company-message__tit {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
@media (min-width: 1176px) {
  .company-message__txt {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
@media (min-width: 1176px) {
  .company-message__txt .txt {
    line-height: 2;
  }
}
.company-message__txt .txt p:not(:last-child) {
  margin-bottom: 2em;
}
@media (min-width: 1176px) {
  .company-message__pic {
    margin-top: clamp(2rem, 3vw, 3rem);
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 1175px) {
  .company-message__pic {
    max-width: 300px;
  }
}
@media (max-width: 767px) {
  .company-message__pic {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1175px) {
  .company-message__pic__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .company-message__pic__inner {
    margin-left: auto;
    margin-right: auto;
    width: clamp(120px, 80%, 200px);
  }
}
.company-message__pic__cap {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.company-message__pic__cap .is-img {
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 80%;
  padding: 0.5em 1em;
}
.company-message__pic__cap .is-txt {
  font-weight: 700;
  font-size: 1.25em;
}

.company-message__bg {
  position: absolute;
  content: "";
  display: block;
  z-index: 1;
  overflow: hidden;
}
@media (min-width: 576px) {
  .company-message__bg {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
}
@media (min-width: 1176px) {
  .company-message__bg {
    width: 34%;
  }
}
@media (max-width: 575px) {
  .company-message__bg {
    width: 100%;
    height: 40vw;
    left: 0;
    bottom: 0;
  }
}
.company-message__bg__inner {
  position: relative;
  height: 100%;
}
.company-message__bg__inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media (min-width: 576px) and (max-width: 1175px) {
  .company-message__bg__inner::after {
    background: rgba(255, 255, 255, 0.7);
  }
}
.company-message__bg__inner > * {
  height: 100%;
}
.company-message__bg .ofi-cover {
  object-position: 0 0;
}

.company-philosophy {
  padding-top: clamp(5rem, 8vw, 8rem);
  padding-bottom: clamp(6rem, 8vw, 8rem);
  margin-bottom: clamp(5rem, 10vw, 10rem);
  position: relative;
  overflow: hidden;
}
.company-philosophy .l-cont {
  position: relative;
  z-index: 2;
}
.company-philosophy__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .company-philosophy__inner {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .company-philosophy__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

@media (min-width: 1176px) {
  .company-philosophy__tit {
    flex-basis: 30%;
  }
}
@media (min-width: 1540px) {
  .company-philosophy__tit {
    flex-basis: 28.25%;
  }
}
.company-philosophy__tit__inner {
  color: #ffffff;
}
.company-philosophy__tit__inner b {
  font-size: calc(2rem + 16 * (100vw - 280px) / 1360);
  font-weight: 700;
  padding-right: clamp(3rem, 6vw, 6rem);
  position: relative;
  display: block;
  margin-bottom: 0.2em;
  line-height: 1;
  letter-spacing: 0.075em;
}
@media (min-width: 1753px) {
  .company-philosophy__tit__inner b {
    font-size: 3.67rem;
  }
}
.company-philosophy__tit__inner small {
  font-family: 'Red Hat Display', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1360);
  margin-top: 0.4em;
  display: block;
  font-weight: 500;
}
@media (min-width: 1540px) {
  .company-philosophy__tit__inner small {
    font-size: 1.33rem;
  }
}

.company-philosophy__txt {
  line-height: 2;
}
@media (min-width: 1176px) {
  .company-philosophy__txt {
    flex-basis: 68%;
  }
}
@media (min-width: 1540px) {
  .company-philosophy__txt {
    flex-basis: 67.32%;
  }
}
.company-philosophy__txt__inner {
  background: #ffffff;
  padding: clamp(1.5rem, 4vw, 4rem) 5%;
}
@media (min-width: 768px) {
  .company-philosophy__txt__inner {
    padding: clamp(2rem, 4vw, 4rem) 8%;
  }
}
@media (min-width: 1540px) {
  .company-philosophy__txt__inner {
    padding: clamp(2rem, 5vw, 5rem) 10%;
  }
}
.company-philosophy__txt__inner .txt {
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .company-philosophy__txt__inner .txt {
    font-size: calc(1rem + 2 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .company-philosophy__txt__inner .txt {
    font-size: 1rem;
  }
}
.company-philosophy__txt__inner .txt p:not(:last-child) {
  margin-bottom: 1.5em;
}
.company-philosophy__txt__inner .txt strong {
  font-size: 1.14em;
}

.company-philosophy__bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.company-philosophy__bg__inner {
  width: 100%;
  height: 100%;
  position: relative;
}
.company-philosophy__bg__inner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: rgba(72, 64, 48, 0.6);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.company-table {
  padding-top: clamp(3rem, 9vw, 9rem);
  margin-bottom: clamp(6rem, 10vw, 10rem);
  display: flex;
  flex-direction: column;
  row-gap: clamp(6rem, 9vw, 9rem);
}
.company-table__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .company-table__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .company-table__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .company-table__tit {
    width: 30.9%;
  }
}
@media (min-width: 1176px) {
  .company-table__table {
    width: 65.55%;
  }
}

.company-access__inner .txt ul {
  text-align: left;
}
.company-access__inner .txt ul li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
  letter-spacing: 0.03em;
}
.company-access__inner .txt ul li::before {
  content: "●";
  color: #1A4069;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}
.company-access__inner .txt ul li:not(:last-child) {
  margin-bottom: 0.75rem;
}
.company-access__tit {
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.company-access__tit b {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  color: #000000;
  font-size: calc(1.5rem + 20 * (100vw - 280px) / 1360);
  font-weight: 700;
  position: relative;
  display: block;
  line-height: 1;
  letter-spacing: 0.075em;
  margin-bottom: 0.2em;
}
@media (min-width: 1540px) {
  .company-access__tit b {
    font-size: 2.44rem;
  }
}
.company-access__tit small {
  font-family: 'Red Hat Display', sans-serif;
  font-size: calc(1rem + 10 * (100vw - 280px) / 1360);
  font-weight: 500;
  color: #1A4069;
  display: block;
}
@media (min-width: 1540px) {
  .company-access__tit small {
    font-size: 1.33rem;
  }
}

.access-map {
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #ffffff;
}
@media (min-width: 1176px) {
  .access-map {
    padding-bottom: 40%;
  }
}
@media (max-width: 1175px) {
  .access-map {
    padding-bottom: 360px;
  }
}
.access-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 1176px) {
  .company-access__flex {
    display: flex;
    justify-content: space-between;
  }
  .company-access__flex > * {
    width: 47.5%;
  }
}
@media (max-width: 1175px) {
  .company-access__flex > *:first-child {
    margin-bottom: 4rem;
  }
}
@media (min-width: 1176px) {
  .company-access__flex .accessmap {
    padding-bottom: 66.66%;
  }
}

.company-access__box__tit {
  margin-bottom: 0.75rem;
  font-size: calc(1rem + 2 * (100vw - 376px) / 1264);
  font-weight: 500;
}
@media (min-width: 1540px) {
  .company-access__box__tit {
    font-size: 1rem;
  }
}

/* about-video */
.about-video {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1100px;
  padding: clamp(2rem, 4vw, 4rem) 0;
  background-color: #F4F1EC;
}
.about-video__tit {
  text-align: center;
  line-height: 1.4;
  margin-bottom: calc(1.5rem + 0.2em);
  position: relative;
  font-weight: 700;
  font-size: calc(1.14rem + 6 * (100vw - 376px) / 1264);
}
@media (min-width: 1540px) {
  .about-video__tit {
    font-size: 1.33rem;
  }
}
.about-video .txt {
  margin-bottom: 2rem;
  padding-left: 7%;
  padding-right: 7%;
}
.about-video__video {
  margin-left: auto;
  margin-right: auto;
  width: 86%;
}
.about-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.about-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------------/
	recruit
/--------------------------------------------------------------------*/
.recruit-merit {
  position: relative;
  width: 100%;
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.recruit-merit::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(160px, 38.54vw, 740px);
  height: clamp(160px, 38.54vw, 740px);
  background: #F4F1EC;
  z-index: 1;
  display: block;
}
.recruit-merit .l-cont_full {
  position: relative;
  z-index: 2;
}
.recruit-merit__inner {
  position: relative;
}

.recruit-merit__tit {
  position: relative;
  padding-left: 10%;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.recruit-merit__tit__inner {
  font-weight: 700;
}
.recruit-merit__tit__inner small {
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  letter-spacing: 0.02em;
  padding-left: 0.5em;
}
@media (min-width: 1540px) {
  .recruit-merit__tit__inner small {
    font-size: 2rem;
  }
}
.recruit-merit__tit__inner strong {
  display: block;
  font-weight: 700;
  font-size: calc(2.5rem + 40 * (100vw - 280px) / 1360);
  line-height: 1;
  position: relative;
}
@media (min-width: 1540px) {
  .recruit-merit__tit__inner strong {
    font-size: 4.44rem;
  }
}
.recruit-merit__tit__inner strong b {
  color: #1A4069;
  font-size: 1.5em;
}
.recruit-merit__tit__inner strong::after {
  position: absolute;
  top: calc(50% + 2px);
  right: calc(100% + 0.2em);
  content: "";
  width: 100%;
  height: 2px;
  background: #1A4069;
}

.recruit-merit__txt {
  padding-left: 10%;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.recruit-merit__txt__inner {
  width: 90%;
}
@media (min-width: 768px) {
  .recruit-merit__txt__inner {
    width: 50%;
  }
}
.recruit-merit__list {
  display: flex;
  counter-reset: merit-num;
}
@media (min-width: 768px) {
  .recruit-merit__list {
    flex-wrap: wrap;
    column-gap: 4.64%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .recruit-merit__list > *:nth-of-type(even) {
    margin-top: 9vw;
  }
}
@media (min-width: 768px) and (min-width: 1540px) {
  .recruit-merit__list > *:nth-of-type(even) {
    margin-top: 134px;
  }
}
@media (max-width: 767px) {
  .recruit-merit__list {
    flex-direction: column;
    row-gap: clamp(2rem, 4vw, 4rem);
  }
}

@media (min-width: 768px) {
  .recruit-merit__box {
    width: 47.68%;
  }
}
@media (max-width: 767px) {
  .recruit-merit__box {
    width: 100%;
  }
}
.recruit-merit__box__inner {
  position: relative;
}
.recruit-merit__box__img {
  position: relative;
  z-index: 1;
  width: 100%;
}
@media (min-width: 768px) {
  .recruit-merit__box__img {
    width: 94%;
  }
}
@media (min-width: 1176px) {
  .recruit-merit__box__img {
    width: 87.37%;
  }
}
.recruit-merit__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.recruit-merit__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  position: relative;
}
.recruit-merit__box__img__inner > div,
.recruit-merit__box__img__inner figure,
.recruit-merit__box__img__inner a,
.recruit-merit__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.recruit-merit__box__txt {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .recruit-merit__box__txt {
    margin-top: -3rem;
  }
}
@media (min-width: 1176px) {
  .recruit-merit__box__txt {
    margin-top: -4rem;
  }
}
@media (max-width: 767px) {
  .recruit-merit__box__txt {
    margin-top: -2rem;
  }
}
.recruit-merit__box__txt__inner {
  width: 100%;
  background: #ffffff;
  padding: 0 5% clamp(1.5rem, 3vw, 3rem);
  position: relative;
}
@media (min-width: 768px) {
  .recruit-merit__box__txt__inner {
    width: 92%;
  }
}
@media (min-width: 1540px) {
  .recruit-merit__box__txt__inner {
    width: 82.52%;
    padding: 0 10% clamp(1.5rem, 3vw, 3rem);
  }
}
.recruit-merit__box__txt__inner::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 2em;
  background: #1A4069;
}
.recruit-merit__box__txt__inner::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2em;
  height: 1px;
  background: #1A4069;
}
.recruit-merit__box__tit {
  font-weight: 700;
  position: relative;
  margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
}
.recruit-merit__box__tit span {
  padding: clamp(1.5rem, 2vw, 2rem) 5% 0.5em 4rem;
  display: block;
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1360);
}
@media (min-width: 576px) {
  .recruit-merit__box__tit span {
    padding: clamp(1.5rem, 2vw, 2rem) 5% 0.5em 12%;
  }
}
@media (min-width: 768px) {
  .recruit-merit__box__tit span {
    font-size: calc(1.25rem + 12 * (100vw - 768px) / 872);
    padding: 2rem 3% 0.5em 14%;
  }
}
@media (min-width: 1540px) {
  .recruit-merit__box__tit span {
    font-size: 1.78rem;
    padding: 2rem 5% 0.5em 4.5rem;
  }
}
.recruit-merit__box__tit span::after {
  content: "";
  position: absolute;
  left: 1.25em;
  top: -1em;
  width: clamp(4rem, 7vw, 7rem);
  width: 1px;
  height: 3.5em;
  transform-origin: bottom left;
  transform: rotate(40deg);
  background: #1A4069;
}
@media (min-width: 768px) {
  .recruit-merit__box__tit span::after {
    top: -1.5em;
    height: calc(3.5rem + 2em);
    left: 0.2em;
  }
}
@media (min-width: 1540px) {
  .recruit-merit__box__tit span::after {
    top: -2em;
    left: 0.2em;
    height: calc(4rem + 3em);
  }
}
.recruit-merit__box__tit::before {
  position: absolute;
  left: 0;
  bottom: calc(100% - 0.4em);
  line-height: 1;
  counter-increment: merit-num;
  content: counter(merit-num, decimal-leading-zero);
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #1A4069;
  font-size: calc(3rem + 32 * (100vw - 280px) / 1360);
}
@media (min-width: 768px) {
  .recruit-merit__box__tit::before {
    font-size: calc(2.5rem + 30 * (100vw - 768px) / 872);
    left: -0.2em;
  }
}
@media (min-width: 1540px) {
  .recruit-merit__box__tit::before {
    font-size: 4.44rem;
    left: -0.3em;
  }
}

/*.recruit-remarks */
.recruit-remarks {
  position: relative;
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.recruit-remarks__inner {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 3vw, 3rem);
}

.recruit-remarks__box {
  border: 1px solid #C9CED2;
  padding: clamp(1.5em, 2vw, 2rem) 5%;
}
@media (min-width: 1176px) {
  .recruit-remarks__box {
    padding: clamp(2em, 3vw, 3rem) 7% clamp(2em, 3vw, 3rem);
  }
}

/*.recruit-introduction */
.recruit-introduction {
  margin-bottom: clamp(6rem, 9vw, 9rem);
  position: relative;
}
.recruit-introduction__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 6vw, 6rem);
}
@media (min-width: 768px) {
  .recruit-introduction__list > *:nth-of-type(odd) .recruit-introduction__box::before {
    width: 80%;
    height: 100%;
  }
  .recruit-introduction__list > *:nth-of-type(odd) .recruit-introduction__box__img::before {
    left: 10%;
  }
  .recruit-introduction__list > *:nth-of-type(odd) .recruit-introduction__box__inner {
    flex-direction: row-reverse;
  }
  .recruit-introduction__list > *:nth-of-type(odd) .recruit-introduction__box__txt {
    padding-left: 5%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .recruit-introduction__list > *:nth-of-type(even) .recruit-introduction__box::before {
    height: 100%;
  }
  .recruit-introduction__list > *:nth-of-type(even) .recruit-introduction__box__img::before {
    right: 10%;
  }
  .recruit-introduction__list > *:nth-of-type(even) .recruit-introduction__box__txt {
    padding-right: 5%;
  }
}

.recruit-introduction__box {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.recruit-introduction__box__inner {
  display: flex;
  width: 100%;
}
@media (min-width: 768px) {
  .recruit-introduction__box__inner {
    column-gap: 5%;
    align-items: center;
  }
}
@media (min-width: 1540px) {
  .recruit-introduction__box__inner {
    column-gap: 5.18%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__inner {
    flex-direction: column;
    row-gap: 1.2em;
  }
}
.recruit-introduction__box__img {
  position: relative;
}
@media (min-width: 768px) {
  .recruit-introduction__box__img {
    padding-bottom: clamp(2rem, 5vw, 5rem);
    width: 45%;
  }
}
@media (min-width: 1540px) {
  .recruit-introduction__box__img {
    width: 47.91%;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__img {
    width: 90%;
    padding-bottom: 1.2rem;
  }
}
.recruit-introduction__box__img::before {
  background: #EFF5FB;
  z-index: -1;
  position: absolute;
  bottom: 0;
  content: "";
}
@media (min-width: 768px) {
  .recruit-introduction__box__img::before {
    height: 100%;
    width: 90%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__img::before {
    left: 10%;
    bottom: 0;
    content: "";
    width: 100vw;
    height: 90%;
  }
}
@media (min-width: 768px) {
  .recruit-introduction__box__txt {
    width: 60%;
  }
}
@media (min-width: 1540px) {
  .recruit-introduction__box__txt {
    width: 46.91%;
  }
}
@media (max-width: 1539px) {
  .recruit-introduction__box__txt {
    padding-right: 5%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__txt {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 1176px) {
  .recruit-introduction__box__txt__inner {
    max-width: 680px;
  }
}
@media (min-width: 1921px) {
  .recruit-introduction__box__txt__inner {
    max-width: 35.41vw;
  }
}
.recruit-introduction__box__tit {
  margin-bottom: clamp(1rem, 2vw, 2rem);
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1360);
}
@media (min-width: 768px) {
  .recruit-introduction__box__tit {
    font-size: calc(1.25rem + 8 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .recruit-introduction__box__tit {
    font-size: 1.67rem;
  }
}

/* recruit-gallery */
.recruit-gallery {
  overflow: hidden;
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(3rem, 6vw, 6rem);
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.recruit-gallery__tit {
  font-weight: 400;
  line-height: 1.4;
  position: relative;
  margin-bottom: clamp(2rem, 5vw, 5rem);
}
.recruit-gallery__tit__inner {
  position: relative;
  padding-right: 1em;
  height: 100%;
}
.recruit-gallery__tit__inner::after {
  content: "";
  position: absolute;
  top: 30%;
  left: 100%;
  width: 100vw;
  height: 2px;
  background: #1A4069;
}
.recruit-gallery__tit b {
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0.03em;
  font-size: calc(2.5rem + 46 * (100vw - 280px) / 1360);
  font-weight: 400;
  padding-right: 0.2em;
  display: inline-block;
}
@media (min-width: 1540px) {
  .recruit-gallery__tit b {
    font-size: 5.33rem;
  }
}
.recruit-gallery__tit small {
  font-size: calc(0.85rem + 12 * (100vw - 280px) / 1360);
  font-weight: 700;
  color: #1A4069;
  display: inline-block;
}
@media (min-width: 1540px) {
  .recruit-gallery__tit small {
    font-size: 1.56rem;
  }
}

.recruit-gallery__box__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.recruit-gallery__box__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.recruit-gallery__box__inner > div,
.recruit-gallery__box__inner figure,
.recruit-gallery__box__inner a,
.recruit-gallery__box__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.recruit-gallery__box figcaption {
  margin-top: 0.5em;
}
@media (max-width: 767px) {
  .is-column .recruit-gallery__box figcaption {
    font-size: 0.71em;
  }
}

.recruit-gallery {
  position: relative;
}
.recruit-gallery .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: clamp(4rem, 8vw, 8rem);
}
.recruit-gallery .swiper-control {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  column-gap: 1.5em;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}
.recruit-gallery .swiper-next_prev {
  display: flex;
  height: 48px;
  flex-shrink: 0;
  width: 102px;
  column-gap: 6px;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-next_prev {
    height: 64px;
    column-gap: 8px;
    width: 136px;
  }
}
@media (min-width: 1176px) {
  .recruit-gallery .swiper-next_prev {
    height: 90px;
    width: 188px;
  }
}
.recruit-gallery .swiper-button-prev,
.recruit-gallery .swiper-button-next {
  margin: 0;
  float: none;
  display: block;
  background-image: none;
  width: 48px;
  height: 48px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-button-prev,
  .recruit-gallery .swiper-button-next {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1176px) {
  .recruit-gallery .swiper-button-prev,
  .recruit-gallery .swiper-button-next {
    width: 90px;
    height: 90px;
  }
}
.recruit-gallery .swiper-button-prev::before,
.recruit-gallery .swiper-button-next::before {
  content: "";
  position: absolute;
  background: transparent;
  border: 1px solid #1F56A9;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.recruit-gallery .swiper-button-prev::after,
.recruit-gallery .swiper-button-next::after {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #1F56A9;
  font-size: 1.5em;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
@media (min-width: 1176px) {
  .recruit-gallery .swiper-button-prev::after,
  .recruit-gallery .swiper-button-next::after {
    font-size: 2.67rem;
  }
}
.recruit-gallery .swiper-button-prev:hover::before,
.recruit-gallery .swiper-button-next:hover::before {
  background: #1A4069;
  border-color: #1A4069;
}
.recruit-gallery .swiper-button-prev:hover::after,
.recruit-gallery .swiper-button-next:hover::after {
  color: #ffffff;
}
.recruit-gallery .swiper-button-prev:after {
  content: "\e902";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.recruit-gallery .swiper-button-prev::after {
  transform: translate(-60%, -50%);
}
.recruit-gallery .swiper-button-next:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.recruit-gallery .swiper-button-next::after {
  transform: translate(-40%, -50%);
}
.recruit-gallery .swiper-scrollbar {
  width: 100%;
  flex-shrink: 1;
  height: 6px;
  z-index: 1;
  background: #f6f6f6;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-scrollbar {
    height: 8px;
  }
}
.recruit-gallery .swiper-scrollbar-drag {
  background: #C9CED2;
  height: 6px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-scrollbar-drag {
    height: 8px;
  }
}
.recruit-gallery .swiper-scrollbar-drag:hover {
  background-color: #1A4069;
}

/* recruit-table */
.recruit-table {
  margin-bottom: clamp(6rem, 12vw, 12rem);
  display: flex;
  flex-direction: column;
  row-gap: clamp(6rem, 9vw, 9rem);
}
.recruit-table__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .recruit-table__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .recruit-table__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
.recruit-table__tit {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 1176px) {
  .recruit-table__tit {
    width: 30.9%;
  }
}
.recruit-table__tit b {
  font-family: 'Red Hat Display', sans-serif;
  color: #000000;
  font-size: calc(2rem + 24 * (100vw - 280px) / 1360);
  font-weight: 400;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 0.2em;
}
@media (min-width: 1540px) {
  .recruit-table__tit b {
    font-size: 3rem;
  }
}
.recruit-table__tit small {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1360);
  color: #1A4069;
  display: block;
}
@media (min-width: 1540px) {
  .recruit-table__tit small {
    font-size: 1.56rem;
  }
}
@media (min-width: 1176px) {
  .recruit-table__table {
    width: 65.55%;
  }
}

/* recruit-form */
.recruit-form__tit {
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.recruit-form__tit b {
  display: block;
  line-height: 1;
  font-family: 'Red Hat Display', sans-serif;
  font-size: calc(2rem + 28 * (100vw - 280px) / 1360);
  margin-bottom: 0.2em;
  font-weight: 400;
}
@media (min-width: 1540px) {
  .recruit-form__tit b {
    font-size: 3.33rem;
  }
}
.recruit-form__tit small {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1360);
  color: #1A4069;
  display: block;
}
@media (min-width: 1540px) {
  .recruit-form__tit small {
    font-size: 1.56rem;
  }
}

/*--------------------------------------------------------------------/
	works
/--------------------------------------------------------------------*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.works-intro {
  padding: clamp(1.5rem, 2vw, 2rem) 5%;
  margin-bottom: clamp(3rem, 6vw, 6rem);
  background: #f6f6f6;
}
@media (min-width: 1176px) {
  .works-intro {
    padding: clamp(2rem, 3vw, 3rem) 8%;
    text-align: center;
  }
}

.works-single {
  overflow: hidden;
}

/* works-slider */
.works-slider {
  position: relative;
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
.works-slider.is-thumbnailslider {
  display: flex;
  flex-direction: column;
  row-gap: clamp(1.5rem, 3vw, 3rem);
}
.works-slider.is-thumbnailslider .works-slider__top__inner {
  max-width: 800px;
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailslider .works-slider__top .slick-arrow i {
    font-size: 5.56rem;
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailslider .works-slider__top .slick-prev {
    top: calc(266px - 0.5em);
    right: calc(100% + 0.5em);
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailslider .works-slider__top .slick-next {
    top: calc(266px - 0.5em);
    left: calc(100% + 0.5em);
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailslider .sp-thumbnail {
    margin-right: 8px;
  }
}
@media (min-width: 1540px) {
  .works-slider.is-thumbnailslider .sp-thumbnail {
    margin-right: 10px;
  }
}
@media (max-width: 1175px) {
  .works-slider.is-thumbnailslider .sp-thumbnail {
    margin-right: 4px;
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .works-slider.is-thumbnailcolumn {
    display: flex;
    flex-direction: column;
    row-gap: clamp(1.5rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__top {
    width: 65.55%;
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__top .slick-arrow i {
    font-size: 2.22rem;
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__top .slick-prev {
    font-size: 0.89rem;
    top: calc(16.3vw - 0.5em);
    right: calc(100% + 0.2em);
  }
}
@media (min-width: 1540px) {
  .works-slider.is-thumbnailcolumn .works-slider__top .slick-prev {
    top: calc(260px - 0.5em);
    right: calc(100% + 0.5em);
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__top .slick-next {
    font-size: 0.89rem;
    top: calc(16.3vw - 0.5em);
    left: calc(100% + 0.2em);
  }
}
@media (min-width: 1540px) {
  .works-slider.is-thumbnailcolumn .works-slider__top .slick-next {
    top: calc(260px - 0.5em);
    left: calc(100% + 0.5em);
  }
}
.works-slider.is-thumbnailcolumn .works-slider__top__inner {
  max-width: 780px;
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__thumbs {
    width: 31.11%;
  }
}
.works-slider.is-thumbnailcolumn .works-slider__thumbs__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__thumbs__list {
    column-gap: 3.125%;
    row-gap: 0.75vw;
  }
}
@media (min-width: 1540px) {
  .works-slider.is-thumbnailcolumn .works-slider__thumbs__list {
    row-gap: 1rem;
  }
}
@media (max-width: 1175px) {
  .works-slider.is-thumbnailcolumn .works-slider__thumbs__list {
    margin-left: auto;
    margin-right: auto;
    column-gap: 1%;
    row-gap: 1vw;
  }
}
@media (min-width: 1176px) {
  .works-slider.is-thumbnailcolumn .works-slider__thumbs .sp-thumbnail {
    width: 31.25%;
  }
}

.works-slider__top {
  position: relative;
}
@media (max-width: 1175px) {
  .works-slider__top {
    margin-bottom: 1.5rem;
  }
}
.works-slider__top__inner {
  width: 90%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.works-slider__top .slick-nav-arrows .slick-arrow {
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
  line-height: 1;
}
.works-slider__top .slick-nav-arrows .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.67rem;
}
.works-slider__top .slick-nav-arrows .slick-arrow i::before {
  color: #C9CED2;
  transition: all 0.3s ease 0s;
}
.works-slider__top .slick-nav-arrows .slick-arrow:hover i::before {
  color: #6398BE;
}
.works-slider__top .slick-nav-arrows .slick-prev {
  top: calc(26.93vw - 0.5em);
  right: 100%;
}
.works-slider__top .slick-nav-arrows .slick-prev:before {
  display: none;
  content: "←";
}
.works-slider__top .slick-nav-arrows .slick-next {
  top: calc(26.93vw - 0.5em);
  left: 100%;
}
.works-slider__top .slick-nav-arrows .slick-next:before {
  display: none;
  content: "→";
}
.works-slider__top .sp-slide {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.works-slider__top .sp-slide__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.works-slider__top .sp-slide__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.works-slider__top .sp-slide__inner > div,
.works-slider__top .sp-slide__inner figure,
.works-slider__top .sp-slide__inner a,
.works-slider__top .sp-slide__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-slider__top .sp-slide figcaption {
  color: #000000;
  line-height: 1.4;
  font-size: 0.9em;
  margin-top: clamp(0.5rem, 1vw, 1rem);
  padding-left: 1.5em;
  position: relative;
}
.works-slider__top .sp-slide figcaption::before {
  position: absolute;
  content: "●";
  font-size: 0.8em;
  top: 0.1em;
  left: 0;
  color: #1A4069;
}

.works-slider__thumbs {
  position: relative;
  max-width: 890px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.works-slider__thumbs__inner {
  width: 100%;
  position: relative;
}
.works-slider__thumbs__list {
  width: 100%;
  overflow: hidden;
}
.works-slider__thumbs .sp-thumbnail {
  transition: all 0.3s ease 0s;
  border: 1px solid transparent;
  width: 14.16vw;
}
@media (max-width: 1175px) {
  .works-slider__thumbs .sp-thumbnail {
    width: 15.83%;
  }
}
.works-slider__thumbs .sp-thumbnail:hover {
  border-color: #1A4069;
}
.works-slider__thumbs .sp-thumbnail__inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.works-slider__thumbs .sp-thumbnail__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.works-slider__thumbs .sp-thumbnail__inner > div,
.works-slider__thumbs .sp-thumbnail__inner figure,
.works-slider__thumbs .sp-thumbnail__inner a,
.works-slider__thumbs .sp-thumbnail__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-slider__thumbs .slick-nav-arrows .slick-arrow {
  display: flex;
  align-items: center;
  line-height: 1;
}
.works-slider__thumbs .slick-nav-arrows .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.89rem;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  border: 1px solid #C9CED2;
  width: 24px;
  height: 24px;
}
@media (min-width: 768px) {
  .works-slider__thumbs .slick-nav-arrows .slick-arrow i {
    font-size: 1.33rem;
    width: 40px;
    height: 40px;
  }
}
.works-slider__thumbs .slick-nav-arrows .slick-arrow i::before {
  color: #C9CED2;
  transition: all 0.3s ease 0s;
}
.works-slider__thumbs .slick-nav-arrows .slick-arrow:hover i {
  background: rgba(99, 152, 190, 0.6);
  border-color: transparent;
}
.works-slider__thumbs .slick-nav-arrows .slick-arrow:hover i::before {
  color: #ffffff;
}
.works-slider__thumbs .slick-nav-arrows .slick-prev {
  top: 50%;
  right: calc(100% - 10px);
  transform: translateY(-50%);
}
@media (min-width: 1176px) {
  .works-slider__thumbs .slick-nav-arrows .slick-prev {
    right: 99%;
  }
}
.works-slider__thumbs .slick-nav-arrows .slick-prev:before {
  display: none;
  content: "←";
}
.works-slider__thumbs .slick-nav-arrows .slick-next {
  top: 50%;
  left: calc(100% - 10px);
  transform: translateY(-50%);
}
@media (min-width: 1176px) {
  .works-slider__thumbs .slick-nav-arrows .slick-next {
    left: calc(99% - 5px);
  }
}
.works-slider__thumbs .slick-nav-arrows .slick-next:before {
  display: none;
  content: "→";
}
.works-slider__thumbs .slider-nav {
  background: #E5E9EC;
}
.works-slider__thumbs .slick-list {
  padding: 0 !important;
}
@media (min-width: 1176px) {
  .works-slider__thumbs .slick-list {
    padding: 0 0 !important;
  }
}
.works-slider__thumbs .slick-track {
  left: 0 !important;
  width: 100%;
  opacity: 1;
}
@media (min-width: 1176px) {
  .works-slider__thumbs .slick-track {
    left: 0 !important;
  }
}
.works-slider__thumbs .slick-slide {
  transition: all 0.3s ease 0s;
  opacity: 1;
}
@media (max-width: 1175px) {
  .works-slider__thumbs .slick-slide {
    margin-right: 6px;
  }
}

/* スライダーを使用しない場合の一枚画像　*/
.works-singleimg__img {
  position: relative;
  max-width: 1080px;
  margin: auto;
  margin-bottom: clamp(2rem, 4vw, 4rem);
}
.works-singleimg__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.works-singleimg__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.works-singleimg__img__inner > div,
.works-singleimg__img__inner figure,
.works-singleimg__img__inner a,
.works-singleimg__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-singleimg__img figcaption {
  line-height: 1.4;
  margin-top: 0.75em;
  font-size: 0.9em;
}

/* works-singlecont*/
.works-singlecont {
  background: #ffffff;
  position: relative;
  padding: clamp(1.5rem, 2.5vw, 2.5rem) 5%;
  margin-bottom: clamp(3rem, 7vw, 7rem);
  border: 1px solid #1A4069;
}
@media (min-width: 1540px) {
  .works-singlecont {
    padding: 3rem 8%;
  }
}

.works-pdf {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
.works-pdf__list {
  display: flex;
}
@media (min-width: 1176px) {
  .works-pdf__list {
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1em;
  }
  .works-pdf__list > * {
    width: 49%;
  }
}
@media (max-width: 1175px) {
  .works-pdf__list {
    flex-direction: column;
    row-gap: 0.5em;
  }
}
.works-pdf__list li a {
  padding: 1.2rem 4% 1.2rem 4rem;
  transition: all 0.3s ease 0s;
  border: 1px solid #1A4069;
  position: relative;
  background: #1A4069;
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  text-decoration: none !important;
  border-radius: 999px;
}
.works-pdf__list li a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.works-pdf__list li a::before {
  position: absolute;
  left: 1.5rem;
  top: 0.9rem;
  transition: all 0.3s ease 0s;
  font-size: 1.78rem;
  color: #ffffff;
}
.works-pdf__list li a:hover {
  background: #6398BE;
  border-color: #6398BE;
  color: #ffffff;
  transition: all 0.3s ease 0s;
}
.works-pdf__list li a:hover::before {
  color: #ffffff;
}

.works-interview {
  margin-bottom: clamp(4rem, 9vw, 9rem);
}
.works-interview__list > *:not(:last-child) {
  margin-bottom: clamp(3rem, 6vw, 6rem);
}

.works-interview__box {
  padding-bottom: clamp(3rem, 6vw, 6rem);
  border-bottom: 1px solid #C9CED2;
}
@media (min-width: 768px) {
  .works-interview__box__flex {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .works-interview__box__flex.is-right {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .works-interview__box__flex.is-left {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  .works-interview__box__flex .works-interview__box__txt {
    flex-basis: 54%;
    padding-top: 1rem;
  }
}
@media (min-width: 1176px) {
  .works-interview__box__flex .works-interview__box__txt {
    flex-basis: 44.44%;
  }
}
@media (max-width: 767px) {
  .works-interview__box__flex .works-interview__box__txt {
    margin-bottom: 1rem;
  }
}
.works-interview__box__catch {
  position: relative;
  font-size: calc(1.2rem + 4 * (100vw - 280px) / 1360);
  margin-bottom: calc(0.5rem + 0.5em);
  padding-left: 1.5em;
  line-height: 1.5;
  font-weight: 700;
}
@media (min-width: 768px) {
  .works-interview__box__catch {
    font-size: calc(1.2rem + 6 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .works-interview__box__catch {
    font-size: 1.33rem;
  }
}
.works-interview__box__catch::before {
  position: absolute;
  top: 0.7em;
  left: 0;
  content: "";
  width: 1em;
  height: 1px;
  background: #31608C;
}
@media (min-width: 768px) {
  .works-interview__box__img {
    flex-basis: 40%;
  }
}
@media (min-width: 1176px) {
  .works-interview__box__img {
    flex-basis: 48.33%;
  }
}
.works-interview__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.works-interview__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.works-interview__box__img__inner > div,
.works-interview__box__img__inner figure,
.works-interview__box__img__inner a,
.works-interview__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-interview__box__img figcaption {
  margin-top: 0.5rem;
  line-height: 1.4;
  z-index: 1;
  padding-left: 1.5rem;
  position: relative;
}
.works-interview__box__img figcaption::before {
  content: "●";
  position: absolute;
  top: 0;
  left: 0;
  color: #729ee0;
}

/* works-video */
.works-video {
  margin-bottom: clamp(3rem, 6vw, 6rem);
  padding: clamp(2rem, 4vw, 4rem) 5%;
  background-color: #F4F1EC;
}
@media (min-width: 1176px) {
  .works-video__inner {
    display: grid;
    grid-template-columns: 1fr 37.5%;
    grid-template-rows: auto 1fr;
    column-gap: 10%;
    row-gap: clamp(1rem, 1.5vw, 2rem);
  }
}
.works-video__tit {
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .works-video__tit {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: calc(1.5rem + 8 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .works-video__tit {
    font-size: 1.44rem;
  }
}
@media (max-width: 1175px) {
  .works-video__tit {
    margin-bottom: clamp(1.5rem, 1.5vw, 1.5rem);
    text-align: center;
  }
}
@media (min-width: 1176px) {
  .works-video__video {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 1175px) {
  .works-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 2rem;
  }
}
.works-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.works-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1176px) {
  .works-video__txt {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

/* works-table */
.works-table {
  margin-bottom: clamp(3rem, 6vw, 6rem);
}
.works-table__inner {
  border: 1px solid #C9CED2;
  padding: clamp(2em, 2vw, 2rem) 5% clamp(1.5em, 1.5vw, 2rem);
}
@media (min-width: 768px) {
  .works-table__inner {
    padding: clamp(2em, 2.5vw, 3rem) 8% clamp(2em, 3vw, 3.5rem);
  }
}
@media (min-width: 1176px) {
  .works-table__inner {
    padding: clamp(2em, 3.5vw, 4rem) 10% clamp(2em, 3vw, 3.5rem);
  }
}
.works-table__tit {
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
  font-weight: 700;
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1360);
}
@media (min-width: 1540px) {
  .works-table__tit {
    font-size: 1.44rem;
  }
}
@media (max-width: 1175px) {
  .works-table__tit {
    text-align: center;
  }
}
.works-table__table {
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.5;
}
.works-table__table tr {
  border-bottom: 1px solid #C9CED2;
}
.works-table__table tr:first-child {
  border-top: 1px solid #C9CED2;
}
.works-table__table th {
  text-align: left;
  font-weight: 700;
  padding: 1rem 0;
}
@media (min-width: 1176px) {
  .works-table__table th {
    padding: 1.25rem 0;
    width: 25%;
    min-width: 180px;
  }
}
@media (min-width: 1540px) {
  .works-table__table th {
    width: 225px;
  }
}
@media (max-width: 1175px) {
  .works-table__table th {
    display: block;
  }
}
@media (min-width: 1176px) {
  .works-table__table td {
    padding: 1.25rem 1.25rem 1.25rem 0.5rem;
  }
}
@media (max-width: 1175px) {
  .works-table__table td {
    padding: 1rem 0;
    display: block;
    padding-top: 0;
    padding-left: 0;
  }
}

.works-access {
  margin-bottom: clamp(3rem, 9vw, 9rem);
  padding: clamp(2rem, 2.5vw, 2.5rem) 5%;
  background-color: #EFF5FB;
}
@media (min-width: 768px) {
  .works-access__inner {
    display: grid;
    grid-template-columns: 46% 50%;
    grid-template-rows: auto auto;
    column-gap: 4%;
  }
}
.works-access__tit {
  font-weight: 700;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1360);
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .works-access__tit {
    align-self: end;
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: calc(1.25rem + 14 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .works-access__tit {
    font-size: 1.44rem;
  }
}
@media (max-width: 767px) {
  .works-access__tit {
    margin-bottom: clamp(1.5rem, 2vw, 2rem);
    text-align: center;
  }
}
@media (min-width: 768px) {
  .works-access__map {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
.works-access__map__inner {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .works-access__map__inner {
    padding-bottom: 50%;
  }
}
@media (min-width: 1540px) {
  .works-access__map__inner {
    padding-bottom: 460px;
  }
}
@media (max-width: 767px) {
  .works-access__map__inner {
    padding-bottom: 360px;
  }
}
.works-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
@media (min-width: 768px) {
  .works-access__txt {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.contact-flex {
  width: 100%;
  position: relative;
  display: flex;
}
@media (min-width: 1176px) {
  .contact-flex {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .contact-flex {
    flex-direction: column;
  }
}
@media (min-width: 1176px) {
  .contact-flex__main {
    width: 75%;
  }
}
@media (min-width: 1540px) {
  .contact-flex__main {
    width: 65.27%;
  }
}
.contact-flex__aside {
  position: relative;
}
@media (min-width: 1176px) {
  .contact-flex__aside {
    width: 22%;
  }
}
@media (min-width: 1540px) {
  .contact-flex__aside {
    width: 22.5%;
  }
}
@media (max-width: 1175px) {
  .contact-flex__aside {
    display: none;
  }
}

.contact-textarea {
  padding-bottom: clamp(3rem, 6vw, 6rem);
}

.contact-intro {
  position: relative;
  margin-bottom: clamp(3rem, 6vw, 6rem);
}
.contact-intro__tit {
  position: relative;
  font-weight: 700;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1360);
  margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
}
@media (min-width: 1176px) {
  .contact-intro__tit {
    font-size: calc(1.25rem + 4 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .contact-intro__tit {
    font-size: 1.33rem;
  }
}

.contact-box {
  position: relative;
  margin-bottom: clamp(2rem, 4vw, 4rem);
}
.contact-box__tit {
  position: relative;
  margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
  padding-left: 1.5em;
  padding-bottom: 1rem;
  border-bottom: 1px solid #C9CED2;
  font-weight: 700;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .contact-box__tit {
    font-size: calc(1.25rem + 4 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .contact-box__tit {
    font-size: 1.33rem;
  }
}
.contact-box__tit::before {
  position: absolute;
  top: 0.7em;
  left: 0;
  content: "";
  width: 1em;
  height: 3px;
  background: #31608C;
}
.contact-box__tel__time {
  letter-spacing: 0.01em;
}
.contact-box__tel a {
  color: #000000;
}
.contact-box__tel__num {
  font-family: 'Red Hat Display', sans-serif;
}
.contact-box__tel__num span {
  font-size: calc(0.8rem + 7 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .contact-box__tel__num span {
    font-size: calc(0.9rem + 6 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .contact-box__tel__num span {
    font-size: 1.11rem;
  }
}
.contact-box__tel__num .tel-link {
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .contact-box__tel__num .tel-link {
    font-size: calc(1.75rem + 6 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .contact-box__tel__num .tel-link {
    font-size: 2rem;
  }
}

@media (min-width: 1176px) {
  .contact-step {
    position: sticky;
    top: 120px;
    left: 0;
  }
}
.contact-step ol {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contact-step ol li {
  color: #6F757B;
  position: relative;
  font-size: 1.07em;
  display: flex;
  align-items: center;
  background: #ffffff;
  z-index: 2;
  border-radius: 999px;
  border: 1px solid #C9CED2;
  width: 100%;
  padding: 1em 1.25em;
}
@media (min-width: 1176px) {
  .contact-step ol li {
    font-size: calc(0.94rem + 1 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .contact-step ol li {
    font-size: 1.07em;
  }
}
.contact-step ol li:not(:last-child) {
  margin-bottom: clamp(0.75rem, 1vw, 1rem);
}
.contact-step ol li:not(:last-child)::before {
  position: absolute;
  content: "";
  background: #C9CED2;
  top: 100%;
  left: calc(1.25em + 8px);
  width: 1px;
  height: 100%;
  z-index: 1;
}
.contact-step ol li span {
  position: relative;
  z-index: 3;
  width: 2rem;
}
.contact-step ol li span::before {
  position: absolute;
  top: calc(50% - 8px);
  left: 0;
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: #979DA4;
  border-radius: 50%;
}
.contact-step ol li b {
  width: auto;
  font-weight: 500;
}
.contact-step ol li.now {
  color: #000000;
  border-color: #1A4069;
}
.contact-step ol li.now span::before {
  background: #1F56A9;
}

.contact-form__tit {
  margin-bottom: clamp(2rem, 3vw, 3rem);
  padding: 0.4em 0;
  color: #1A4069;
  text-align: center;
  background-color: #f6f6f6;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  font-size: calc(1.25rem + 10 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .contact-form__tit {
    font-size: calc(1.5rem + 4 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .contact-form__tit {
    font-size: 1.67rem;
  }
}

.contact-privacy {
  border: 1px solid #979DA4;
}
@media (min-width: 1176px) {
  .contact-privacy {
    padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(2rem, 3.5vw, 3rem);
    margin-top: 1.5rem;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #C9CED2 #ffffff;
    max-height: 300px;
  }
  .contact-privacy::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy::-webkit-scrollbar-track {
    background: #f6f6f6;
  }
  .contact-privacy::-webkit-scrollbar-thumb {
    background: #C9CED2;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #ffffff;
  }
}
@media (min-width: 1176px) {
  .contact-privacy__inner {
    margin-top: 1.5rem;
    padding: 0 5%;
  }
}
.contact-privacy__tit {
  font-size: calc(1.14rem + 4 * (100vw - 376px) / 1264);
  font-weight: 700;
  text-align: center;
  padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
  position: relative;
}
@media (min-width: 1176px) {
  .contact-privacy__tit {
    margin-bottom: clamp(1rem, 2vw, 2rem);
  }
  .contact-privacy__tit::before {
    content: "";
    position: absolute;
    width: 4rem;
    height: 3px;
    bottom: 0;
    left: calc(50% - 2rem);
    border-radius: 2px;
    background: #1A4069;
  }
  .contact-privacy__tit .privacy-btn {
    display: none;
  }
}
@media (min-width: 1540px) {
  .contact-privacy__tit {
    font-size: 1.22rem;
  }
}
@media (max-width: 1175px) {
  .contact-privacy__tit {
    padding: 1.5rem 6%;
    cursor: pointer;
    position: relative;
  }
  .contact-privacy__tit .privacy-btn {
    display: block;
    position: absolute;
    top: calc(50% - 16px);
    right: 1rem;
    width: 32px;
    height: 32px;
    transition: all 0.3s ease 0s;
  }
  .contact-privacy__tit .privacy-btn i {
    font-size: 1.78rem;
    line-height: 1;
    color: #979DA4;
  }
  .contact-privacy__tit .privacy-btn.is-active {
    transform: rotate(180deg);
  }
}
@media (max-width: 1175px) {
  .contact-privacy__txt {
    padding: 2em 5% clamp(2rem, 3.5vw, 3.5rem);
  }
}
.contact-privacy .txt {
  font-size: 0.94em;
}

.contact-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  line-height: 1.2;
  position: relative;
}
.contact-table th, .contact-table td {
  text-align: left;
}
@media (min-width: 1176px) {
  .contact-table th, .contact-table td {
    display: table-cell;
    padding: 1rem 0 1.5rem;
  }
}
@media (max-width: 1175px) {
  .contact-table th, .contact-table td {
    display: block;
    padding: 0;
  }
}
.contact-table th {
  vertical-align: top;
  font-weight: 500;
}
@media (min-width: 1176px) {
  .contact-table th {
    width: 33%;
    line-height: 1.5;
    font-size: 0.94em;
  }
}
.contact-table th span {
  color: #ffffff;
  margin-left: 0.3rem;
  padding: 0.2em 0.75em;
  font-size: 0.8125em;
}
@media (min-width: 1176px) {
  .contact-table td {
    width: auto;
  }
}
@media (max-width: 1175px) {
  .contact-table td {
    padding: 1em 0 2em;
  }
}
.contact-table textarea {
  width: 100%;
}

input,
select {
  width: auto;
}
input.long,
select.long {
  width: 100%;
}
input.middle,
select.middle {
  width: 100%;
}
@media (min-width: 768px) {
  input.middle,
  select.middle {
    width: 70%;
  }
}
input.short,
select.short {
  width: 70%;
}
@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}
input.mini,
select.mini {
  width: 6em;
}

button {
  outline: none;
  font-size: 1em;
  letter-spacing: 0.1em;
}

button[class*=sub-btn],
button[class*=back-btn],
button[class*=chack-btn] {
  font-size: 1em;
  letter-spacing: 0.1em;
}

button[class*=sub-btn] {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #1A4069;
  border: 1px solid;
  border-color: #1A4069;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  button[class*=sub-btn] {
    font-size: 24px;
  }
}
button[class*=sub-btn]:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button[class*=sub-btn]:hover {
  color: #ffffff;
  background-color: #6398BE;
  border-color: #6398BE;
}
button[class*=sub-btn]:hover::after {
  color: #ffffff;
}
button[class*=sub-btn]::before {
  content: "";
  justify-self: start;
}
button[class*=sub-btn]::after {
  justify-self: end;
  font-size: 1.5em;
}

button[class*=back-btn] {
  margin-top: 1em;
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #4B4D4E;
  background-color: #E5E9EC;
  border: 1px solid;
  border-color: #E5E9EC;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  button[class*=back-btn] {
    font-size: 24px;
  }
}
button[class*=back-btn]:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button[class*=back-btn]:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
button[class*=back-btn]:hover::after {
  color: #ffffff;
}
button[class*=back-btn]::before {
  content: "";
  justify-self: start;
}
button[class*=back-btn]::after {
  justify-self: end;
  font-size: 1.5em;
}

button[class*=chack-btn] {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #1A4069;
  border: 1px solid;
  border-color: #1A4069;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  button[class*=chack-btn] {
    font-size: 24px;
  }
}
button[class*=chack-btn]:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button[class*=chack-btn]:hover {
  color: #ffffff;
  background-color: #6398BE;
  border-color: #6398BE;
}
button[class*=chack-btn]:hover::after {
  color: #ffffff;
}
button[class*=chack-btn]::before {
  content: "";
  justify-self: start;
}
button[class*=chack-btn]::after {
  justify-self: end;
  font-size: 1.5em;
}

[type=button],
[type=submit] {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: block;
  margin-top: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
@media (min-width: 1921px) {
  [type=button],
  [type=submit] {
    max-width: 16.66vw;
  }
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
/* radio-btn */
.mwform-radio-field {
  cursor: pointer;
}
.mwform-radio-field:not(:last-child) {
  margin-right: 1em;
}

.mwform-radio-field-text {
  vertical-align: top;
  position: relative;
  padding-left: 34px;
  display: block;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=radio] + .mwform-radio-field-text:before {
  content: "";
  background: #fdfdfd;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 20px / 2);
  left: 0;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}
input[type=radio]:checked + .mwform-radio-field-text:before {
  background-color: #1A4069;
  box-shadow: inset 0 0 0 5px #f6f6f6;
}
input[type=radio]:focus + .mwform-radio-field-text:before {
  outline: none;
  border-color: #1A4069;
}
input[type=radio]:disabled + .mwform-radio-field-text:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #fdfdfd;
}
input[type=radio] + .mwform-radio-field-text:empty:before {
  margin-right: 0;
}

.vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
input[type=checkbox] {
  display: none;
}

.check-box .vertical-item:not(:first-of-type) {
  margin-top: 1.5rem !important;
}
.check-box label {
  position: relative;
  cursor: pointer;
}
.check-box label::before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: -0.15em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 4;
}

.mwform-checkbox-field-text {
  vertical-align: top;
  padding-left: calc(26px + 1rem);
  display: block;
}
.mwform-checkbox-field-text::before, .mwform-checkbox-field-text::after {
  position: absolute;
  height: 0;
  width: 5.2px;
  background-color: #1A4069;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 3;
}
.mwform-checkbox-field-text::before {
  top: 22.36px;
  left: 10.4px;
  transform: rotate(-135deg);
}
.mwform-checkbox-field-text::after {
  top: 13px;
  left: 0.78px;
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
  border-color: #1A4069;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::after,
.mwform-checkbox-field-text.checked::after {
  height: 13px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::before,
.mwform-checkbox-field-text.checked::before {
  height: 31.2px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1.3px #ffffff;
}

.mw_wp_form .error {
  margin-top: 0.7rem;
}

/* form-parts
----------------------------------------------------------------*/
select, textarea, input {
  background-color: #fdfdfd;
  padding: 0.7em 1em;
  border: 1px solid #C9CED2;
  line-height: 1.5;
}
select:focus, textarea:focus, input:focus {
  background-color: #ffffff;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input:-ms-input-placeholder, input:-moz-placeholder, input::-webkit-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}

/*--------------------------------------------------------------------/
	news
/--------------------------------------------------------------------*/
.news-intro {
  padding: clamp(1.5rem, 2vw, 2rem) 5%;
  margin-bottom: clamp(3rem, 6vw, 6rem);
  background: #f6f6f6;
}
@media (min-width: 1176px) {
  .news-intro {
    padding: clamp(2rem, 3vw, 3rem) 8%;
    text-align: center;
  }
}

.news-single__inner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.news-pdf {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
.news-pdf__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(0.5em, 1vw, 1em);
}
@media (min-width: 1176px) {
  .news-pdf__list {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .news-pdf__list li {
    width: 49%;
  }
}
.news-pdf__list li a {
  padding: 1.25em 4% 1.25em 4.5em;
  transition: all 0.3s ease 0s;
  border: 1px solid #C9CED2;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 1.4;
  color: #000000;
  text-decoration: none !important;
  font-weight: 500;
  border-radius: 999px;
}
.news-pdf__list li a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.news-pdf__list li a::before {
  position: absolute;
  left: 1.25em;
  top: 0.8em;
  font-size: 1.6em;
  color: #C9CED2;
}
.news-pdf__list li a:hover {
  background: #979DA4;
  border-color: #979DA4;
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.blog-intro {
  padding: clamp(1.5rem, 2vw, 2rem) 5%;
  margin-bottom: clamp(3rem, 6vw, 6rem);
  background: #f6f6f6;
}
@media (min-width: 1176px) {
  .blog-intro {
    padding: clamp(2rem, 3vw, 3rem) 8%;
    text-align: center;
  }
}

@media (min-width: 1176px) {
  .contents-flex {
    display: flex;
    justify-content: space-between;
  }
}

.contents-left {
  width: 100%;
}
@media (min-width: 1176px) {
  .contents-left {
    width: calc(96% - 320px);
  }
}

.blog-mainimg {
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
}
.blog-mainimg figcaption {
  line-height: 1.4;
  margin-top: 0.75em;
  font-size: 0.9em;
}

.blog-pdf {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
.blog-pdf__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(0.5em, 1vw, 1em);
}
.blog-pdf__list li a {
  padding: 1.25em 4% 1.25em 4.5em;
  transition: all 0.3s ease 0s;
  border: 1px solid #C9CED2;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 1.4;
  color: #000000;
  text-decoration: none !important;
  font-weight: 500;
  border-radius: 999px;
}
.blog-pdf__list li a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blog-pdf__list li a::before {
  position: absolute;
  left: 1.25em;
  top: 0.8em;
  font-size: 1.6em;
  color: #C9CED2;
}
.blog-pdf__list li a:hover {
  background: #979DA4;
  border-color: #979DA4;
  color: #ffffff;
}

/* blog-video */
.blog-video {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
@media (min-width: 1176px) {
  .blog-video__inner {
    display: grid;
    grid-template-columns: 1fr 37.5%;
    grid-template-rows: auto 1fr;
    column-gap: 10%;
    row-gap: clamp(1rem, 1.5vw, 2rem);
  }
}
.blog-video__tit {
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1360);
}
@media (min-width: 1176px) {
  .blog-video__tit {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: calc(1.5rem + 8 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .blog-video__tit {
    font-size: 1.78rem;
  }
}
@media (max-width: 1175px) {
  .blog-video__tit {
    margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
  }
}
@media (min-width: 1176px) {
  .blog-video__video {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 1175px) {
  .blog-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}
.blog-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.blog-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1176px) {
  .blog-video__txt {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

/* blog-table */
.blog-table {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
.blog-table__tit {
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
  font-weight: 700;
  font-size: calc(1.25rem + 8 * (100vw - 280px) / 1360);
}
@media (min-width: 1540px) {
  .blog-table__tit {
    font-size: 1.56rem;
  }
}
.blog-table__table {
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.5;
}
.blog-table__table tr {
  border-bottom: 1px solid #C9CED2;
}
.blog-table__table tr:first-child {
  border-top: 1px solid #C9CED2;
}
.blog-table__table th {
  text-align: left;
  font-weight: 700;
  padding: 1rem 0;
}
@media (min-width: 1176px) {
  .blog-table__table th {
    padding: 1.25rem 0;
    width: 25%;
    min-width: 180px;
  }
}
@media (min-width: 1540px) {
  .blog-table__table th {
    width: 225px;
  }
}
@media (max-width: 1175px) {
  .blog-table__table th {
    display: block;
    padding-bottom: 0;
  }
}
@media (min-width: 1176px) {
  .blog-table__table td {
    padding: 1.25rem 1.25rem 1.25rem 0.5rem;
  }
}
@media (max-width: 1175px) {
  .blog-table__table td {
    padding: 1rem 0;
    display: block;
    padding-top: 0;
    padding-left: 0;
  }
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
@media (min-width: 1176px) {
  .aside-cont.is-sticky {
    position: sticky;
    top: 120px;
    left: 0;
  }
}
.aside-cont__inner {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 4vw, 4rem);
}

.aside-box__tit {
  padding: 0.3rem 0 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #C9CED2;
  font-weight: 500;
  font-size: 1.25em;
}
@media (min-width: 1176px) {
  .aside-box__tit {
    font-size: calc(1.14rem + 4 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .aside-box__tit {
    font-size: 1.22rem;
  }
}
.aside-box__list.is-category {
  display: flex;
  flex-direction: column;
}
.aside-box__list.is-category li {
  border-bottom: 1px solid #E5E9EC;
}
.aside-box__list.is-category li a {
  color: #000000;
  text-decoration: none !important;
  display: flex;
  column-gap: 0.5em;
  padding: 0.75em 0.5em 0.75em 0.2em;
  position: relative;
}
.aside-box__list.is-category li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #1A4069;
}
.aside-box__list.is-category li a:hover::before {
  width: 100%;
}
.aside-box__list.is-category li a .is-circle {
  padding-top: 0.3em;
  font-size: 0.7em;
  text-decoration: none !important;
}
.aside-box__list.is-category li a .is-text {
  font-size: 0.94em;
  font-weight: 500;
}

.select-display {
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #C9CED2;
  padding: 1em;
  position: relative;
}
.select-display__btn {
  display: block;
  position: absolute;
  top: calc(50% - 16px);
  right: 1rem;
  width: 32px;
  height: 32px;
  transition: all 0.3s ease 0s;
}
.select-display__btn i {
  font-size: 1.78rem;
  line-height: 1;
  color: #979DA4;
}
.select-display.is-active .select-display__btn {
  transform: rotate(180deg);
}
.select-display .is-text {
  display: block;
  width: 85%;
}

.select-list.is-active {
  position: relative;
  top: -1px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #C9CED2;
  padding: 0.75em 1em 1.25em;
  z-index: 2;
}

.wp-block-archives-list li,
.wp-block-latest-posts__list li {
  border-bottom: 1px solid #E5E9EC;
}
.wp-block-archives-list li a,
.wp-block-latest-posts__list li a {
  color: #000000;
  text-decoration: none !important;
  display: block;
  padding: 0.75em 0.5em 0.75em 0;
  position: relative;
}
@media (min-width: 1176px) {
  .wp-block-archives-list li a,
  .wp-block-latest-posts__list li a {
    font-size: 0.94em;
  }
}
.wp-block-archives-list li a::before,
.wp-block-latest-posts__list li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #1A4069;
}
.wp-block-archives-list li a:hover::before,
.wp-block-latest-posts__list li a:hover::before {
  width: 100%;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #000000;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.3em;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tag-cloud-link::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.875em;
  color: #979DA4;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #1A4069;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:hover {
  color: #1A4069;
}
.tag-cloud-link:hover::before {
  color: #1A4069;
}
.tag-cloud-link:hover::after {
  width: 100%;
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #C9CED2;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}
.wp-calendar-table caption {
  padding: 0.3rem 2%;
  font-weight: 500;
}
.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}
.wp-calendar-table td {
  text-align: center;
  background: #f6f6f6;
  line-height: 2;
  border: 2px solid #ffffff;
  font-size: 0.94em;
}
.wp-calendar-table td a {
  background-color: #1A4069;
  color: #ffffff;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}
.wp-calendar-table td a:hover {
  background-color: #31608C;
}
.wp-calendar-table td.pad {
  background: transparent;
}
.wp-calendar-table td#today {
  background: #979DA4;
  color: #ffffff;
}
.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}
.wp-calendar-nav a {
  display: block;
  color: #000000;
  padding: 0.2em 1rem;
  background-color: #f6f6f6;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.wp-calendar-nav a:hover {
  background-color: #1A4069;
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
  position: relative;
  width: 100%;
  border: 1px solid #C9CED2 !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wp-block-search__input {
  height: 44px;
  padding: 0 10px !important;
  outline: 0;
  background: #ffffff;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}

.wp-block-search__button {
  margin: 0;
  height: 44px !important;
  width: 50px !important;
  padding: 2px !important;
  background: #1A4069 !important;
  font-size: 18px !important;
  vertical-align: bottom !important;
  color: #ffffff !important;
  transition: all 0.3s ease 0s;
  border: none !important;
  text-align: center;
  cursor: pointer !important;
}
.wp-block-search__button:hover {
  color: #ffffff !important;
  background-color: #6398BE !important;
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives-dropdown {
  position: relative;
  margin-top: 1em;
}
.wp-block-archives-dropdown:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wp-block-archives-dropdown::after {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  line-height: 1;
  color: #979DA4;
  font-size: 1.17rem;
}
.wp-block-archives-dropdown > label {
  display: none !important;
}
.wp-block-archives-dropdown select {
  position: relative;
  padding-right: 1.5em;
  width: 100% !important;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  overflow: hidden;
  background-color: rgba(229, 233, 236, 0.7);
}
.main-view__inner {
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .main-view__inner {
    height: 300px;
  }
}
@media (min-width: 1176px) {
  .main-view__inner {
    height: 30vw;
  }
}
@media (min-width: 1540px) {
  .main-view__inner {
    height: 500px;
  }
}
@media (min-width: 1921px) {
  .main-view__inner {
    height: 25vw;
  }
}
@media (max-width: 767px) {
  .main-view__inner {
    height: 230px;
  }
}
.main-view__bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.main-view__bg__img {
  width: 100%;
  height: 100%;
  position: relative;
}
.main-view__bg__img:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.main-view__bg__img__inner {
  width: 100%;
  height: 100%;
}
.main-view .l-cont {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.main-view__tit {
  font-weight: 400;
  line-height: 1.4;
  position: relative;
  width: 100%;
  padding-top: clamp(2em, 4vw, 5rem);
}
.main-view__tit > em {
  display: block;
  margin-top: 0.5em;
  font-style: normal;
  font-size: calc(1rem + 10 * (100vw - 280px) / 1360);
  font-weight: 500;
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0.1em;
  color: #1A4069;
}
@media (min-width: 1753px) {
  .main-view__tit > em {
    font-size: 1.33rem;
  }
}
.main-view__tit > em.is-404 {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  font-size: calc(2rem + 32 * (100vw - 280px) / 1360);
  letter-spacing: 0.02em;
  color: #1A4069;
}
@media (min-width: 1540px) {
  .main-view__tit > em.is-404 {
    font-size: 3.56rem;
  }
}
.main-view__tit > span {
  display: block;
  font-size: calc(1.4rem + 20 * (100vw - 280px) / 1360);
  font-weight: 700;
}
@media (min-width: 1540px) {
  .main-view__tit > span {
    letter-spacing: 0.075em;
    font-size: 2.22rem;
  }
}
.main-view__tit > span.is-404 {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  font-size: calc(2.5rem + 32 * (100vw - 280px) / 1360);
  letter-spacing: 0.02em;
  color: #000000;
}
@media (min-width: 1540px) {
  .main-view__tit > span.is-404 {
    font-size: 4rem;
  }
}
.main-view__tit > span.is-category {
  display: inline-block;
  font-weight: 700;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  margin-top: 0.1em;
  color: #000000;
  letter-spacing: 0;
}
@media (min-width: 1540px) {
  .main-view__tit > span.is-category {
    font-size: 1.56rem;
  }
}
.main-view__tit > span.is-category b {
  font-weight: 700;
  display: inline-block;
  content: "";
  vertical-align: middle;
  width: 0.6em;
  height: 0.1em;
  margin: 0 0.5em 0.2em;
  background: #000000;
}
@media (min-width: 1540px) {
  .main-view__tit > span.is-category b {
    font-size: 2rem;
  }
}
.main-view__tit > span.is-category span {
  display: inline-block;
  color: #1A4069;
}
@media (min-width: 1540px) {
  .main-view__tit > span.is-category span {
    font-size: 2rem;
  }
}
.main-view__tit > span.is-category span em {
  padding-right: 1em;
  font-style: normal;
}
.main-view__tit > span.is-archive {
  font-weight: 700;
  font-size: calc(1.25rem + 20 * (100vw - 280px) / 1360);
  margin-top: 0.3em;
  color: #000000;
}
@media (min-width: 1540px) {
  .main-view__tit > span.is-archive {
    font-size: 2.22rem;
  }
}
.main-view .cl--white span, .main-view .cl--white em {
  color: #ffffff;
}
.main-view .cl--white .breadcrumb__list li {
  color: #ffffff;
}
.main-view .cl--white .breadcrumb__list li a {
  color: #ffffff;
}

.main-view__txt {
  display: flex;
  flex-direction: column;
  row-gap: clamp(1em, 1.5vw, 1.5rem);
  padding-top: 1rem;
}
@media (min-width: 1176px) {
  .main-view__txt {
    padding-top: clamp(2em, 3.5vw, 3.5rem);
  }
}
.main-view__txt__sub {
  font-weight: 400;
  line-height: 1.4;
  position: relative;
  width: 100%;
}
.main-view__txt__sub em {
  font-family: 'Red Hat Display', sans-serif;
  padding-left: 0.4em;
  font-style: normal;
  font-size: calc(0.8rem + 8 * (100vw - 280px) / 1360);
  font-weight: 500;
  color: #979DA4;
}
@media (min-width: 1540px) {
  .main-view__txt__sub em {
    font-size: 0.89rem;
  }
}
.main-view__txt__sub span {
  font-size: calc(0.9rem + 9 * (100vw - 280px) / 1360);
  font-weight: 700;
  color: #1A4069;
}
@media (min-width: 1540px) {
  .main-view__txt__sub span {
    font-size: 1.11rem;
  }
}
.main-view__txt__tit {
  font-weight: 700;
  line-height: 1.4;
  position: relative;
  width: 100%;
  font-size: calc(1.1rem + 20 * (100vw - 280px) / 1360);
}
@media (min-width: 1753px) {
  .main-view__txt__tit {
    font-size: 1.89rem;
  }
}
.main-view__txt__category li {
  display: inline-block;
  margin-right: 0.2em;
}
@media (max-width: 1175px) {
  .main-view__txt__category .category-item {
    font-size: 0.75em;
  }
}

.contents {
  padding-top: clamp(4rem, 6vw, 6rem);
}

.common-column4 {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 5vw, 5rem);
}

@media (min-width: 1176px) {
  .common-category {
    display: flex;
    column-gap: 3%;
  }
}
@media (min-width: 1176px) {
  .common-category__tit {
    width: clamp(130px, 10%, 144px);
    font-size: calc(1.14rem + 4 * (100vw - 1080px) / 560);
    flex-shrink: 0;
    padding-top: 0.2em;
  }
}
@media (min-width: 1540px) {
  .common-category__tit {
    font-size: 1.22rem;
  }
}
@media (max-width: 1175px) {
  .common-category__tit {
    padding: 0.3rem 0 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #C9CED2;
    font-weight: 500;
    font-size: 1.25em;
    font-family: 'Red Hat Display', sans-serif;
  }
}
.common-category__list {
  display: flex;
}
@media (min-width: 1176px) {
  .common-category__list {
    flex-wrap: wrap;
    gap: 0.75em 0.5em;
    flex-shrink: 1;
  }
}
@media (max-width: 1175px) {
  .common-category__list {
    flex-direction: column;
  }
}
@media (max-width: 1175px) {
  .common-category__list li {
    border-bottom: 1px solid #E5E9EC;
  }
}
.common-category__list li a {
  color: #000000;
  text-decoration: none !important;
  display: flex;
  column-gap: 0.5em;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1176px) {
  .common-category__list li a {
    padding: 0.4em 1.25em 0.4em 1em;
    border: 1px solid #C9CED2;
    border-radius: 999px;
  }
}
@media (max-width: 1175px) {
  .common-category__list li a {
    padding: 0.75em 0.5em 0.75em 0.2em;
  }
}
@media (max-width: 1175px) {
  .common-category__list li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 1px;
    transition: all 0.3s ease 0s;
    background: #1A4069;
  }
}
@media (min-width: 1176px) {
  .common-category__list li a:hover {
    border-color: #6398BE;
    color: #6398BE;
  }
}
@media (max-width: 1175px) {
  .common-category__list li a:hover::before {
    width: 100%;
  }
}
.common-category__list li a .is-circle {
  padding-top: 0.5em;
  font-size: 0.7em;
  text-decoration: none !important;
}
.common-category__list li a .is-text {
  font-weight: 500;
}

.common-flex {
  width: 100%;
  position: relative;
  display: flex;
}
@media (min-width: 1176px) {
  .common-flex {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .common-flex {
    flex-direction: column;
    row-gap: clamp(3rem, 5vw, 5rem);
  }
}
@media (min-width: 1176px) {
  .common-flex.is-right {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1176px) {
  .common-flex__main {
    width: 72%;
  }
}
@media (min-width: 1540px) {
  .common-flex__main {
    width: 76.16%;
  }
}
.common-flex__aside {
  position: relative;
}
@media (min-width: 1176px) {
  .common-flex__aside {
    width: 25%;
  }
}
@media (min-width: 1540px) {
  .common-flex__aside {
    width: 19.83%;
  }
}
@media (max-width: 1175px) {
  .common-flex__aside {
    width: 100%;
  }
}

.common-flex__list {
  display: flex;
}
.common-flex__list.is-col1 {
  flex-direction: column;
  row-gap: clamp(1.5rem, 3vw, 3rem);
}
@media (min-width: 576px) {
  .common-flex__list.is-col1 {
    row-gap: clamp(1.5rem, 2vw, 2rem);
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col2 {
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col2 {
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col2 {
    column-gap: 4.5%;
  }
}
@media (max-width: 575px) {
  .common-flex__list.is-col2 {
    flex-direction: column;
    row-gap: clamp(1.5rem, 3vw, 3rem);
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col2 > * {
    flex-basis: 48.5%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col2 > * {
    flex-basis: 47.75%;
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col3 {
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col3 {
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col3 {
    column-gap: 1.25%;
  }
}
@media (min-width: 1540px) {
  .common-flex__list.is-col3 {
    column-gap: 2.8%;
  }
}
@media (max-width: 575px) {
  .common-flex__list.is-col3 {
    flex-direction: column;
    row-gap: clamp(1.5rem, 3vw, 3rem);
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 48.5%;
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 32%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 32.5%;
  }
}
@media (min-width: 1540px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 31.46%;
  }
}

/*--------------------------------------------------------------------/
	404 page
/--------------------------------------------------------------------*/
.cont-404__inner {
  background: #ffffff;
  border: 1px solid #C9CED2;
  padding: clamp(2rem, 4vw, 4rem) 5%;
}
.cont-404__tit {
  font-size: calc(1.25rem + 10 * (100vw - 280px) / 1360);
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
  text-align: center;
}
@media (min-width: 1540px) {
  .cont-404__tit {
    font-size: 2rem;
  }
}
.cont-404__tit span {
  position: relative;
}
@media (min-width: 768px) {
  .cont-404__txt {
    text-align: center;
  }
}
@media (min-width: 1540px) {
  .cont-404__txt {
    font-size: 1rem;
  }
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont__txt {
  margin-bottom: 4rem;
}
.single-cont__txt::after {
  display: block;
  content: "";
  clear: both;
}
@media (min-width: 768px) {
  .single-cont__txt {
    margin-bottom: 6rem;
  }
}
.single-cont__thumbnail {
  float: right;
  width: 50%;
  margin-left: 6%;
  margin-bottom: 3rem;
}
.single-cont__foot {
  padding-top: clamp(3rem, 5vw, 5rem);
}

.single-cont__tag {
  margin-bottom: 1em;
}
.single-cont__tag a {
  font-size: 0.94em;
  color: #000000;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.3em;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.single-cont__tag a:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-cont__tag a::before {
  position: absolute;
  top: 0.5em;
  left: 0.2rem;
  font-size: 0.875em;
  color: #979DA4;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.single-cont__tag a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #1A4069;
  transition: all 0.3s ease 0s;
}
.single-cont__tag a:hover {
  color: #1A4069;
}
.single-cont__tag a:hover::before {
  color: #1A4069;
}
.single-cont__tag a:hover::after {
  width: 100%;
}

.addtoany_shortcode {
  padding-bottom: 1.5em;
}

.single-sub {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .single-sub {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
  }
}

.single-date {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  line-height: 1.4;
  color: #979DA4;
  margin-right: 1rem;
}

/* align */
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
  margin-left: 1.5em;
}

.alignleft {
  float: left;
  margin-right: 1.5em;
}