@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.contact-flex {
	width: 100%;
	position: relative;
	display: flex;
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
	}
	&__main {
		@include media-breakpoint-up(lg) {
			width: 75%;
		}
		@include media-breakpoint-up(xl) {
			width: 65.27%;
		}
	}
	&__aside {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 22%;
		}
		@include media-breakpoint-up(xl) {
			width: 22.5%;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.contact-textarea {
	padding-bottom: clamp(3rem,6vw,6rem);
}

.contact-intro {
	position: relative;
	margin-bottom: clamp(3rem,6vw,6rem);
	&__tit {
		position: relative;
		@include f-w(700);
		@include f-s_xxs(1.14,6);
		margin-bottom: clamp(1rem,1.5vw,1.5rem);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25,4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

.contact-box {
	position: relative;
	margin-bottom: clamp(2rem,4vw,4rem);
	&__tit {
		position: relative;
		margin-bottom: clamp(1rem,1.5vw,1.5rem);
		padding-left: 1.5em;
		padding-bottom: 1rem;
		border-bottom: 1px solid $m-gray;
		@include f-w(700);
		@include f-s_xxs(1.14,6);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25,4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			position: absolute;
			top: 0.7em;
			left: 0;
			content: "";
			width: 1em;
			height: 3px;
			background: $sub_c;
		}
	}
	&__tel {
		&__time {
			@include l-sp(0.01em);
		}
		a {
			color: $txt_c;
		}
		&__num {
			@include f-family(font02);
			span {
				@include f-s_xxs(.8,7);
				@include media-breakpoint-up(lg) {
					@include f-s_lg(0.9,6);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(20)
				}
			}
			.tel-link {
				@include f-s_xxs(1.14,16);
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1.75,6);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(36)
				}
			}
		}
	}
}

.contact-step {
	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 120px;
		left: 0;
	}
	& ol {
		display: flex;
		flex-direction: column;
		width: 100%;
		& li {
			color: $dl-gray;
			position: relative;
			font-size: 1.07em;
			display: flex;
			align-items: center;
			background: $white;
			z-index: z-index(module, part02);
			@include radius(999px);
			border: 1px solid $m-gray;
			width: 100%;
			padding: 1em 1.25em;
			@include media-breakpoint-up(lg) {
				@include f-s_lg(0.94,1);
			}
			@include media-breakpoint-up(xl) {
				font-size: 1.07em;
			}
			&:not(:last-child) {
				margin-bottom: clamp(0.75rem, 1vw, 1rem);
				&::before {
					position: absolute;
					content: "";
					background: $m-gray;
					top: 100%;
					left: calc(1.25em + 8px);
					width: 1px;
					height: 100%;
					z-index: z-index(module, part01);
				}
			}
			span {
				position: relative;
				z-index: z-index(module, part03);
				width: 2rem;
				&::before {
					position: absolute;
					top: calc(50% - 8px);
					left: 0;
					content: "";
					display: block;
					width: 16px;
					height: 16px;
					background: $gray;
					@include radius(50%);
				}
			}
			b {
				width: auto;
				@include f-w(500);
			}
			&.now {
				color: $txt_c;
				border-color: $main_c;
				& span::before {
					background: $other_c1;
				}
			}
		}
	}
}

.contact-form {
	&__tit {
		margin-bottom: clamp(2rem,3vw,3rem);
		padding: .4em 0;
		color: $main_c;
		text-align: center;
		background-color: $p-gray;
		@include f-family(font02);
		@include f-w(500);
		@include f-s_xxs(1.25,10);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.5,4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(30);
		}
	}
}

.contact-privacy {
	border: 1px solid $gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(2rem, 3.5vw, 3rem);
		margin-top: 1.5rem;
		overflow-y: scroll;
		@include scroll-bar;
		max-height: 300px;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			margin-top: 1.5rem;
			padding: 0 5%;
		}
	}
	&__tit {
		@include f-s_xs(1.14, 4);
		@include f-w(700);
		text-align: center;
		padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
		position: relative;
		@include media-breakpoint-up(lg) {
			margin-bottom: clamp(1rem, 2vw, 2rem);
			&::before {
				content: "";
				position: absolute;
				width: 4rem;
				height: 3px;
				bottom: 0;
				left: calc(50% - 2rem);
				border-radius: 2px;
				background: $main_c;
			}
			.privacy-btn {
				display: none;
			}
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			cursor: pointer;
			position: relative;
			.privacy-btn {
				display: block;
				position: absolute;
				top: calc(50% - 16px);
				right: 1rem;
				width: 32px;
				height: 32px;
				@include transition;
				i {
					@include f-size(32);
					@include line-h(1);
					color: $gray;
				}
				&.is-active {
					transform: rotate(180deg);
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-down(md) {
			padding: 2em 5% clamp(2rem, 3.5vw, 3.5rem);
		}
	}
	.txt {
		font-size: 0.94em;
	}
}

.contact-table {
	width: 100%;
	@include m-a;
	margin-bottom: 2rem;
	line-height: 1.2;
	position: relative;
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 1.5rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
			padding: 0;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(500);
		@include media-breakpoint-up(lg) {
			width: 33%;
			line-height: 1.5;
			font-size: 0.94em;
		}
		& span {
			color: $white;
			margin-left: 0.3rem;
			padding: .2em .75em;
			font-size: 0.8125em;
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		@include media-breakpoint-down(md) {
			padding: 1em 0 2em;
		}
	}
	& textarea {
		width: 100%;
	}
}

input,
select {
	width: auto;
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 70%;
		}
	}
	&.short {
		width: 70%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 6em;
	}
}

button {
	outline: none;
	font-size: 1em;
	@include l-sp(0.1em);
}

button[class*="sub-btn"],
button[class*="back-btn"],
button[class*="chack-btn"] {
	font-size: 1em;
	@include l-sp(0.1em);
}
button[class*="sub-btn"] {
	@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
}
button[class*="back-btn"] {
	margin-top: 1em;
	@include btn_arrow($d-gray, $l-gray, $l-gray, $white, $black, $black);
}
button[class*="chack-btn"] {
	@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
}


%form-btn {
	width: 100%;
	max-width: 240px;
	margin: 0 auto;
	display: block;
	margin-top: 2rem;
	padding: 0.5rem 2rem;
	@include transition;
	cursor: pointer;
	font-size: 0.94em;
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(240 / 1920), 4) * 100vw;
	}
}

[type="button"],
[type="submit"] {
	width: 100%;
	max-width: 320px;
	margin: 0 auto;
	display: block;
	margin-top: 1rem;
	@include transition;
	cursor: pointer;
	outline: none;
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(320 / 1920), 4) * 100vw;
	}
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
$radio_height: 20px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスの「「アニメーションのスピード
$checkbox_height: 26px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスの「「アニメーションのスピード

/* radio-btn */
.mwform-radio-field {
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 1em;
	}
}
.mwform-radio-field-text {
	vertical-align: top;
	position: relative;
	padding-left: 34px;
	display: block;
}
input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .mwform-radio-field-text {
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: absolute;
			top: calc(50% - calc(#{$radio_height}/ 2));
			left: 0;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .mwform-radio-field-text {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .mwform-radio-field-text {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .mwform-radio-field-text {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .mwform-radio-field-text {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}
.vertical-item:not(:first-of-type) {
	margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}

.check-box {
	& .vertical-item:not(:first-of-type) {
		margin-top: 1.5rem !important;
	}
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: -0.15em;
			left: 0;
			display: block;
			transition: border-color ease calc($duration_checkbox/2);
			z-index: z-index(module, part04);
		}
	}
}

.mwform-checkbox-field-text {
	vertical-align: top;
	padding-left: calc(#{$checkbox_height} + 1rem);
	display: block;
	&::before,
	&::after {
		position: absolute;
		height: 0;
		width: $checkbox_height * 0.2;
		background-color: $main_c;
		display: inline-block;
		transform-origin: left top;
		content: "";
		transition: opacity ease 0.5;
		z-index: z-index(module, part03);
	}
	&::before {
		top: $checkbox_height * 0.86;
		left: $checkbox_height * 0.4;
		transform: rotate(-135deg);
	}
	&::after {
		top: $checkbox_height * 0.5;
		left: $checkbox_height * 0.03;
		transform: rotate(-45deg);
	}
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck calc($duration_checkbox/2) ease 0s forwards;
	}

	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.mw_wp_form .error {
	margin-top: 0.7rem;
}

/* form-parts
----------------------------------------------------------------*/
%form-parts {
	background-color: $o-white;
	padding: 0.7em 1em;
	border: 1px solid $m-gray;
	line-height: 1.5;
	&:focus {
		background-color: $white;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}

textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}
