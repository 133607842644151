@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	works
/--------------------------------------------------------------------*/
@use "../_plugins/slick" as *; //slick

.works-intro {
	padding: clamp(1.5rem, 2vw, 2rem) 5%;
	margin-bottom: clamp(3rem, 6vw, 6rem);
	background: $p-gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(2rem, 3vw, 3rem) 8%;
		text-align: center;
	}
}


//------------------------------------------------------------//
// single page
//------------------------------------------------------------//

.works-single {
	overflow: hidden;
}
/* works-slider */
.works-slider {
	position: relative;
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&.is-thumbnailslider {
		@include flex-column;
		row-gap: clamp(1.5rem, 3vw, 3rem);
		.works-slider__top {
			&__inner {
				max-width: 800px;
			}
			.slick-arrow {
				& i {
					@include media-breakpoint-up(lg) {
						@include f-size(100);
					}
				}
			}
			.slick-prev {
				@include media-breakpoint-up(lg) {
					top: calc(266px - 0.5em);
					right: calc(100% + 0.5em);
				}
			}
			.slick-next {
				@include media-breakpoint-up(lg) {
					top: calc(266px - 0.5em);
					left: calc(100% + 0.5em);
				}
			}
		}
		& .sp-thumbnail {
			@include media-breakpoint-up(lg) {
				margin-right: 8px;
			}
			@include media-breakpoint-up(xl) {
				margin-right: 10px;
			}
			@include media-breakpoint-down(md) {
				margin-right: 4px;
			}
		}
	}
	&.is-thumbnailcolumn {
		@include media-breakpoint-up(lg) {
			@include flex-between;
		}
		@include media-breakpoint-down(md) {
			@include flex-column;
			row-gap: clamp(1.5rem, 3vw, 3rem);
		}
		.works-slider__top {
			@include media-breakpoint-up(lg) {
				width: 65.55%;
			}
			.slick-arrow {
				& i {
					@include media-breakpoint-up(lg) {
						@include f-size(40);
					}
				}
			}
			.slick-prev {
				@include media-breakpoint-up(lg) {
					@include f-size(16);
					top: calc(16.3vw - 0.5em);
					right: calc(100% + 0.2em);
				}
				@include media-breakpoint-up(xl) {
					top: calc(260px - 0.5em);
					right: calc(100% + 0.5em);
				}
			}
			.slick-next {
				@include media-breakpoint-up(lg) {
					@include f-size(16);
					top: calc(16.3vw - 0.5em);
					left: calc(100% + 0.2em);
				}
				@include media-breakpoint-up(xl) {
					top: calc(260px - 0.5em);
					left: calc(100% + 0.5em);
				}
			}
			&__inner {
				max-width: 780px;
			}
		}
		.works-slider__thumbs {
			@include media-breakpoint-up(lg) {
				width: 31.11%;
			}
			&__list {
				@include flex-wrap;
				@include media-breakpoint-up(lg) {
					column-gap: 3.125%;
					row-gap: 0.75vw;
				}
				@include media-breakpoint-up(xl) {
					row-gap: 1rem;
				}
				@include media-breakpoint-down(md) {
					@include m-a;
					column-gap: 1%;
					row-gap: 1vw;
				}
			}
			& .sp-thumbnail {
				@include media-breakpoint-up(lg) {
					width: 31.25%;
				}
			}
		}
	}
}

.works-slider__top {
	position: relative;
	@include media-breakpoint-down(md) {
		margin-bottom: 1.5rem;
	}
	&__inner {
		width: 90%;
		position: relative;
		@include m-a;
	}
	.slick-nav-arrows {
		.slick-arrow {
			display: flex;
			align-items: center;
			@include transition;
			@include line-h(1);
			& i {
				display: flex;
				justify-content: center;
				align-items: center;
				@include f-size(48);
				&::before {
					color: $m-gray;
					@include transition;
				}
			}
			&:hover {
				i::before {
					color: $hover_c;
				}
			}
		}
		.slick-prev {
			top: calc(26.93vw - 0.5em);
			right: 100%;
			&:before {
				display: none;
				content: $slick-prev-character;
			}
		}
		.slick-next {
			top: calc(26.93vw - 0.5em);
			left: 100%;
			&:before {
				display: none;
				content: $slick-next-character;
			}
		}
	}
	.sp-slide {
		position: relative;
		overflow: hidden;
		@include m-a;
		&__inner {
			@include aspect-img;
		}
		& figcaption {
			color: $black;
			line-height: 1.4;
			font-size: 0.9em;
			margin-top: clamp(0.5rem, 1vw, 1rem);
			padding-left: 1.5em;
			position: relative;
			&::before {
				position: absolute;
				content: "●";
				font-size: 0.8em;
				top: 0.1em;
				left: 0;
				color: $main_c;
			}
		}
	}
}

.works-slider__thumbs {
	position: relative;
	max-width: 890px;
	width: 100%;
	@include m-a;
	&__inner {
		width: 100%;
		position: relative;
	}
	&__list {
		width: 100%;
		overflow: hidden;
	}
	& .sp-thumbnail {
		@include transition;
		border: 1px solid transparent;
		width: 14.16vw;
		@include media-breakpoint-down(md) {
			width: 15.83%;
		}
		&:hover {
			border-color: $main_c;
		}
		&__inner {
			overflow: hidden;
			@include aspect-img(1, 1);
		}
	}
	.slick-nav-arrows {
		.slick-arrow {
			display: flex;
			align-items: center;
			@include line-h(1);
			& i {
				display: flex;
				justify-content: center;
				align-items: center;
				@include f-size(16);
				@include radius(50%);
				@include transition;
				border: 1px solid $m-gray;
				width: 24px;
				height: 24px;
				@include media-breakpoint-up(md) {
					@include f-size(24);
					width: 40px;
					height: 40px;
				}
				&::before {
					color: $m-gray;
					@include transition;
				}
			}
			&:hover {
				i {
					background: rgba($hover_c, 0.6);
					border-color: transparent;
					&::before {
						color: $white;
					}
				}
			}
		}
		.slick-prev {
			top: 50%;
			right: calc(100% - 10px);
			transform: translateY(-50%);
			@include media-breakpoint-up(lg) {
				right: 99%;
			}
			&:before {
				display: none;
				content: $slick-prev-character;
			}
		}
		.slick-next {
			top: 50%;
			left: calc(100% - 10px);
			transform: translateY(-50%);
			@include media-breakpoint-up(lg) {
				left: calc(99% - 5px);
			}
			&:before {
				display: none;
				content: $slick-next-character;
			}
		}
	}
	& .slider-nav {
		background: $l-gray;
	}
	& .slick-list {
		padding: 0 !important;
		@include media-breakpoint-up(lg) {
			padding: 0 0 !important;
		}
	}
	& .slick-track {
		left: 0 !important;
		width: 100%;
		opacity: 1;
		@include media-breakpoint-up(lg) {
			left: 0 !important;
		}
	}
	& .slick-slide {
		@include transition;
		opacity: 1;
		@include media-breakpoint-down(md) {
			margin-right: 6px;
		}
	}
}

/* スライダーを使用しない場合の一枚画像　*/
.works-singleimg {
	&__img {
		position: relative;
		max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
		margin: auto;
		margin-bottom: clamp(2rem, 4vw, 4rem);
		&__inner {
			@include aspect-img;
		}
		& figcaption {
			line-height: 1.4;
			margin-top: 0.75em;
			font-size: 0.9em;
		}
	}
}

/* works-singlecont*/
.works-singlecont {
	background: $white;
	position: relative;
	padding: clamp(1.5rem, 2.5vw, 2.5rem) 5%;
	margin-bottom: clamp(3rem, 7vw, 7rem);
	border: 1px solid $main_c;
	@include media-breakpoint-up(xl) {
		padding: 3rem 8%;
	}
}

// works-pdf
.works-pdf {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__list {
		display: flex;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			column-gap: 2%;
			row-gap: 1em;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 0.5em;
		}
		& li {
			a {
				padding: 1.2rem 4% 1.2rem 4rem;
				@include transition;
				border: 1px solid $main_c;
				position: relative;
				background: $main_c;
				width: 100%;
				height: 100%;
				display: block;
				color: $white;
				@include dec-none;
				@include radius(999px);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.5rem;
					top: 0.9rem;
					@include transition;
					@include f-size(32);
					color: $white;
				}
				&:hover {
					background: $hover_c;
					border-color: $hover_c;
					color: $white;
					@include transition;
					&::before {
						color: $white;
					}
				}
			}
		}
	}
}

// works-interview
$works_interview_txt_w_md: 54%; //テキストの横幅 md
$works_interview_txt_w_lg: 44.44%; //テキストの横幅 lg
$works_interview_img_w_md: 40%; //画像の横幅 md
$works_interview_img_w_lg: 48.33%; //画像の横幅 lg
.works-interview {
	margin-bottom: clamp(4rem, 9vw, 9rem);
	&__list {
		& > * {
			&:not(:last-child) {
				margin-bottom: clamp(3rem, 6vw, 6rem);
			}
		}
	}
}

.works-interview__box {
	padding-bottom: clamp(3rem, 6vw, 6rem);
	border-bottom: 1px solid $m-gray;
	&__flex {
		@include media-breakpoint-up(md) {
			@include flex-between;
		}
		&.is-right {
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
		}
		&.is-left {
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
			}
		}
		.works-interview__box__txt {
			@include media-breakpoint-up(md) {
				flex-basis: $works_interview_txt_w_md;
				padding-top: 1rem;
			}
			@include media-breakpoint-up(lg) {
				flex-basis: $works_interview_txt_w_lg;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 1rem;
			}
		}
	}
	&__catch {
		position: relative;
		@include f-s_xxs(1.2, 4);
		margin-bottom: calc(0.5rem + 0.5em);
		padding-left: 1.5em;
		@include line-h(1.5);
		@include f-w(700);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.2, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			position: absolute;
			top: 0.7em;
			left: 0;
			content: "";
			width: 1em;
			height: 1px;
			background: $sub_c;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			flex-basis: $works_interview_img_w_md;
		}
		@include media-breakpoint-up(lg) {
			flex-basis: $works_interview_img_w_lg;
		}
		&__inner {
			@include aspect-img;
		}
		figcaption {
			margin-top: 0.5rem;
			line-height: 1.4;
			z-index: 1;
			padding-left: 1.5rem;
			position: relative;
			&::before {
				content: "●";
				position: absolute;
				top: 0;
				left: 0;
				color: $other_c3;
			}
		}
	}
}


/* works-video */
.works-video {
	margin-bottom: clamp(3rem, 6vw, 6rem);
	padding: clamp(2rem, 4vw, 4rem) 5%;
	background-color: $bg_c3;
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 1fr 37.5%;
			grid-template-rows: auto 1fr;
			column-gap: 10%;
			row-gap: clamp(1rem, 1.5vw, 2rem);
		}
	}
	&__tit {
		@include f-w(700);
		@include line-h(1.4);
		@include f-s_xxs(1.14, 14);
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			@include f-s_lg(1.5, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(26);
		}
		@include media-breakpoint-down(md) {
			margin-bottom: clamp(1.5rem, 1.5vw, 1.5rem);
			text-align: center;
		}
	}
	&__video {
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 2rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
	}
}

/* works-table */
.works-table {
	margin-bottom: clamp(3rem, 6vw, 6rem);
	&__inner {
		border: 1px solid $m-gray;
		padding: clamp(2em, 2vw, 2rem) 5% clamp(1.5em, 1.5vw, 2rem);
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 2.5vw, 3rem) 8% clamp(2em, 3vw, 3.5rem);
		}
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 3.5vw, 4rem) 10% clamp(2em, 3vw, 3.5rem);
		}
	}
	&__tit {
		text-align: center;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include f-w(700);
		@include f-s_xxs(1.14, 14);
		@include media-breakpoint-up(xl) {
			@include f-size(26);
		}
		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}
	&__table {
		margin-bottom: 2rem;
		width: 100%;
		@include line-h(1.5);
		& tr {
			border-bottom: 1px solid $m-gray;
			&:first-child {
				border-top: 1px solid $m-gray;
			}
		}
		& th {
			text-align: left;
			@include f-w(700);
			padding: 1rem 0;
			@include media-breakpoint-up(lg) {
				padding: 1.25rem 0;
				width: 25%;
				min-width: 180px;
			}
			@include media-breakpoint-up(xl) {
				width: 225px;
			}
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
		& td {
			@include media-breakpoint-up(lg) {
				padding: 1.25rem 1.25rem 1.25rem 0.5rem;
			}
			@include media-breakpoint-down(md) {
				padding: 1rem 0;
				display: block;
				padding-top: 0;
				padding-left: 0;
			}
		}
	}
}

// works-access
.works-access {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	padding: clamp(2rem, 2.5vw, 2.5rem) 5%;
	background-color: $bg_c1;
	&__inner {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 46% 50%;
			grid-template-rows: auto auto;
			column-gap: 4%;
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xxs(1.14, 16);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(md) {
			align-self: end;
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			@include f-s_md(1.25, 14);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(26);
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: clamp(1.5rem, 2vw, 2rem);
			text-align: center;
		}
	}
	&__map {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		&__inner {
			margin-bottom: 1rem;
			position: relative;
			padding: 0;
			height: 0;
			overflow: hidden;
			background-color: $white;
			@include media-breakpoint-up(md) {
				padding-bottom: 50%;
			}
			@include media-breakpoint-up(xl) {
				padding-bottom: 460px;
			}
			@include media-breakpoint-down(sm) {
				padding-bottom: 360px;
			}
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
	}
}
