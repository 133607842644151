@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	free01
/--------------------------------------------------------------------*/
.free01-intro {
	margin-bottom: clamp(3rem, 5vw, 5rem);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__tit {
		position: relative;
		&__inner {
			@include f-w(700);
			@include f-s_xxs(1.2, 16);
			@include l-sp(0.02em);
			@include media-breakpoint-up(xxl) {
				@include f-size(34);
			}
			small {
				display: block;
				@include f-s_xxs(1, 16);
				@include l-sp(0.02em);
				padding-left: 0.5em;
				@include media-breakpoint-up(xl) {
					@include f-size(36);
				}
			}
			strong {
				display: block;
				@include f-w(700);
				@include f-s_xxs(3, 32);
				@include line-h(1);
				position: relative;
				@include media-breakpoint-up(xl) {
					@include f-size(80);
				}
				b {
					color: $main_c;
					font-size: 1.5em;
				}
				&::after {
					position: absolute;
					top: calc(50% + 2px);
					right: calc(100% + 0.2em);
					content: "";
					width: 100%;
					height: 2px;
					background: $main_c;
				}
			}
			&::before {
				position: absolute;
				top: 0.7em;
				right: calc(100% + 1em);
				content: "";
				width: 100%;
				height: 1px;
				background-color: $main_c;
				@include media-breakpoint-down(md) {
					right: calc(100% + .7em);
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			width: 63%;
		}
		.txt {
			@include f-s_xxs(0.96, 4);
			@include f-w(500);
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
}

// free01-nav
.free01-nav {
	margin-bottom: clamp(8rem, 14vw, 14rem);
	&__tit {
		@include f-family(font02);
		@include l-sp(0);
		margin-bottom: 1rem;
		@include f-w(400);
		position: relative;
		padding-left: 1.5em;
		color: $txt_c;
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			content: "";
			position: absolute;
			top: 0.5em;
			left: 0;
			transform-origin: bottom left;
			transform: rotate(-45deg);
			width: 2em;
			height: 1px;
			background: $txt_c;
		}
	}
	&__list {
		display: flex;
		counter-reset: free01-navnum;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			justify-content: space-between;
			row-gap: 1.5em;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 0.75em;
		}
	}
}

.free01-nav__item {
	@include media-breakpoint-up(lg) {
		width: 49%;
	}
	@include media-breakpoint-up(xl) {
		width: 48.33%;
	}
	a {
		display: block;
		@include transition;
		color: $txt_c;
		position: relative;
		border: 1px solid $hover_c;
		@include radius(999px);
		@include dec-none;
		padding: 1.25em 3.5rem 1.25em 2em;
		@include media-breakpoint-up(lg) {
			padding: 1.5em 3.5rem 1.5em 2em;
		}
		&:hover {
			border-color: $hover_c;
			background-color: $main_c;
			color: $white;
			.free01-nav__item__tit::before {
				color: $white;
			}
			.free01-nav__item__arrow {
				background: $hover_c;
			}
		}
	}
	&__tit {
		position: relative;
		@include f-w(500);
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		/*&::before {
			position: absolute;
			top: 50%;
			left: -7px;
			color: $hover_c;
			@include transition;
			transform: translateY(-50%);
			counter-increment: free01-navnum;
			content: counter(free01-navnum, decimal-leading-zero);
			@include line-h(1);
			@include f-family(font02);
			@include l-sp(0);
			font-size: 1.75em;
			@include f-w(500);
		}*/
	}
	&__arrow {
		position: absolute;
		right: 1em;
		top: 50%;
		transform: translateY(-50%);
		width: 36px;
		height: 36px;
		@include radius(50%);
		@include transition;
		background: $hover_c;
		i {
			position: relative;
			color: $white;
			display: block;
			width: 100%;
			height: 100%;
			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include f-size(24);
			}
		}
	}
}

.free01-list {
	@include flex-column;
	row-gap: clamp(7rem, 14vw, 14rem);
	counter-reset: free01-num;
}

// free01-box
.free01-box {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		@include media-breakpoint-up(md) {
			top: 50%;
			transform: translateY(-50%);
			height: 100%;
			width: 70%;
		}
		@include media-breakpoint-down(sm) {
			height: 86%;
			width: 100%;
			top: 2rem;
			left: 0;
		}
	}
	&__inner {
		position: relative;
		display: flex;
		overflow: hidden;
		padding-bottom: 4em;
		@include media-breakpoint-up(md) {
			column-gap: 4%;
			justify-content: space-between;
			width: 100%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(1.5rem, 2.5vw, 2rem);
			@include m-a;
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
	}
	&:nth-of-type(odd) {
		&::before {
			left: 0;
			background-color: $bg_c3;
		}
		.free01-box__inner {
			@include media-breakpoint-up(md) {
				padding-left: map-get($contmargin, tb);
			}
		}
		.free01-box__txt { 
			@include media-breakpoint-up(md) {
				justify-content: flex-end;
			}
		}
		.free01-box__img {
			&::before {
				background: $bg_c3;
				@include media-breakpoint-up(md) {
					left: 2rem;
				}
				@include media-breakpoint-up(xl) {
					left: 3rem;
				}
			}
		}
	}
	&:nth-of-type(even) {
		&::before {
			right: 0;
			background-color: $bg_c1;
		}
		.free01-box__inner {
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
				padding-right: map-get($contmargin, tb);
			}
		}
		.free01-box__img {
			&::before {
				background: $bg_c1;
				@include media-breakpoint-up(md) {
					right: 2rem;
				}
				@include media-breakpoint-up(xl) {
					right: 3rem;
				}
			}
		}
	}
}

.free01-box__txt {
	position: relative;
	z-index: z-index(module, part03);
	@include media-breakpoint-up(md) {
		width: 45.5%;
		display: flex;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 2em;
		width: calc(52% - 100px);
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	&__inner {
		@include media-breakpoint-up(md) {
			max-width: 640px;
		}
		@include media-breakpoint-up(xl) {
			width: 640px;
		}
	}
	&__tit {
		overflow: hidden;
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		position: relative;
		/*&::before {
			counter-increment: free01-num;
			content: counter(free01-num, decimal-leading-zero);
			@include f-family(font02);
			color: $main_c;
			position: absolute;
			top: 0;
			left: 0;
			@include l-sp(0.01em);
			@include f-w(400);
			@include line-h(0.9);
			@include f-s_xxs(1.75, 32);
			@include media-breakpoint-up(xs) {
				@include f-s_xs(2.25, 30);
			}
			@include media-breakpoint-up(md) {
				@include f-s_md(2.25, 24);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}*/
		span {
			@include f-w(700);
			@include f-s_xxs(1.14, 18);
			position: relative;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.4, 20);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	.btn-wrap {
		margin-top: 2.5rem;
		@include media-breakpoint-down(sm) {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.free01-box__img {
	position: relative;
	z-index: z-index(module, part03);
	@include media-breakpoint-up(md) {
		width: 48.75%;
		padding-bottom: clamp(3rem, 4vw, 4rem);
	}
	@include media-breakpoint-up(xl) {
		width: calc(48% - 24px);
		padding-bottom: 4rem;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding-bottom: clamp(1.5rem, 3vw, 3rem);
	}
	/*&::before {
		content: "";
		position: absolute;
		z-index: -1;
		@include media-breakpoint-up(md) {
			height: 50%;
			width: 100%;
			bottom: 0;
		}
		@include media-breakpoint-down(sm) {
			height: 85%;
			width: 100vw;
			left: 1.5rem;
			bottom: 0;
		}
	}*/
	&__inner {
		@include media-breakpoint-up(md) {
			height: 100%;
			& > div,
			& > a {
				height: 100%;
			}
		}
		@include media-breakpoint-up(xl) {
			@include aspect-img;
		}
	}
}
