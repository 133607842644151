@use "../_variables/variables" as *;
@use "../_mixins/break-points" as *;
@use "effects" as *;
@use "others" as *;
@use "text" as *;

@mixin btn_base {
	display: block;
	text-align: center;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
	@include media-breakpoint-up(xxl) {
		font-size: 24px;
	}
}

// btn_normal
@mixin btn_normal($color:$white,$bg:$main_c,$border:$main_c,$color_h:$white,$bg_h:$hover_c,$border_h:$hover_c){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1.25em 4%;
	width: 100%;
	&:hover{
		color:$color_h;
		background-color:$bg_h;
		border-color:$border_h;
	}
}

// btn_arrow
@mixin btn_arrow($color:$white,$bg:$main_c,$border:$main_c,$color_h:$white,$bg_h:$hover_c,$border_h:$hover_c){
	@include btn_base;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	column-gap: .5em;
	align-items: center;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1.18em 4%;
	width: 100%;
	@include radius(999px);
	@include icon(arrow2_right,after);
	&:hover{
		color:$color_h;
		background-color:$bg_h;
		border-color:$border_h;
		&::after {
			color: $color_h;
		}
	}
	&::before {
		content: "";
		justify-self: start;
	}
	&::after {
		justify-self: end;
		font-size: 1.5em;
	}
}

// btn_line
@mixin btn_line($color:$main_c,$bg:$white,$border:$main_c,$color_h:$white,$bg_h:$point_c,$border_h:$black){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1.5rem 4%;
	width: 100%;
	@include f-w(500);
	&:hover{
		color: $color_h;
		background-color:$bg_h;
		border-color:$border_h;
		&::after {
			width: 4em;
			background-color:$border_h;
		}
	}
	&::after {
		position: absolute;
		top:50%;
		left: calc(100% - 1.5em);
		width: 3em;
		height: 1px;
		content: "";
		@include transition;
		background: $border;
	}
}
