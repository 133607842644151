@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/
// company-message
.company-message {
	margin-bottom: clamp(4rem, 12vw, 12rem);
	padding-top: clamp(2rem, 7vw, 7rem);
	padding-bottom: clamp(6rem, 8vw, 8rem);
	position: relative;
	background: $white;
	overflow: hidden;
	@include media-breakpoint-down(xs) {
		padding-bottom: calc(4rem + 40vw);
	}
	& .l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		position: relative;
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 59.72% 30.55%;
			column-gap: 9.73%;
		}
		@include media-breakpoint-down(md) {
			@include flex-column;
			width: 100%;
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		.txt {
			@include media-breakpoint-up(lg) {
				@include line-h(2);
			}
			p {
				&:not(:last-child) {
					margin-bottom: 2em;
				}
			}
		}
	}
	&__pic {
		@include media-breakpoint-up(lg) {
			margin-top: clamp(2rem, 3vw, 3rem);
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(md) {
			max-width: 300px;
		}
		@include media-breakpoint-down(sm) {
			@include m-a;
		}
		&__inner {
			@include media-breakpoint-down(md) {
				@include flex-column;
				justify-content: center;
			}
			@include media-breakpoint-down(sm) {
				@include m-a;
				width: clamp(120px,80%,200px);
			}
		}
		&__cap {
			margin-top: 1rem;
			@include m-a;
			text-align: center;
			.is-img {
				background: $white;
				@include m-a;
				display: block;
				width: 80%;
				padding: 0.5em 1em;
			}
			.is-txt {
				@include f-w(700);
				font-size: 1.25em;
			}
		}
	}
}

.company-message__bg {
	position: absolute;
	content: "";
	display: block;
	z-index: z-index(module, part01);
	overflow: hidden;
	@include media-breakpoint-up(sm) {
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;
	}
	@include media-breakpoint-up(lg) {
		width: 34%;
	}
	@include media-breakpoint-down(xs) {
		width: 100%;
		height: 40vw;
		left: 0;
		bottom: 0;
	}
	&__inner {
		position: relative;
		height: 100%;
		&::after {
			content: "";
			//background: rgba($white, 0.4);
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@include media-breakpoint-between(sm, md) {
				background: rgba($white, 0.7);
			}
		}
		& > * {
			height: 100%;
		}
	}
	.ofi-cover {
		object-position: 0 0;
	}
}

// company-philosophy
.company-philosophy {
	padding-top: clamp(5rem, 8vw, 8rem);
	padding-bottom: clamp(6rem, 8vw, 8rem);
	margin-bottom: clamp(5rem, 10vw, 10rem);
	position: relative;
	overflow: hidden;
	.l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
}

.company-philosophy {
	&__tit {
		@include media-breakpoint-up(lg) {
			flex-basis: 30%;
		}
		@include media-breakpoint-up(xl) {
			flex-basis: 28.25%;
		}
		&__inner {
			color: $white;
			b {
				@include f-s_xxs(2, 16);
				@include f-w(700);
				padding-right: clamp(3rem, 6vw, 6rem);
				position: relative;
				display: block;
				margin-bottom: 0.2em;
				@include line-h(1);
				@include l-sp(0.075em);
				@include media-breakpoint-up(xxl) {
					@include f-size(66);
				}
			}
			small {
				@include f-family(font02);
				@include f-s_xxs(1, 12);
				margin-top: 0.4em;
				display: block;
				@include f-w(500);
				@include media-breakpoint-up(xl) {
					@include f-size(24);
				}
			}
		}
	}
}

.company-philosophy {
	&__txt {
		@include line-h(2);
		@include media-breakpoint-up(lg) {
			flex-basis: 68%;
		}
		@include media-breakpoint-up(xl) {
			flex-basis: 67.32%;
		}
		&__inner {
			background: $white;
			padding: clamp(1.5rem, 4vw, 4rem) 5%;
			@include media-breakpoint-up(md) {
				padding: clamp(2rem, 4vw, 4rem) 8%;
			}
			@include media-breakpoint-up(xl) {
				padding: clamp(2rem, 5vw, 5rem) 10%;
			}
			.txt {
				@include f-s_xxs(0.94, 4);
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1, 2);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(18);
				}
				p {
					&:not(:last-child) {
						margin-bottom: 1.5em;
					}
				}
				strong {
					font-size: 1.14em;
				}
			}
		}
	}
}

.company-philosophy {
	&__bg {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: z-index(module, part01);
		&__inner {
			width: 100%;
			height: 100%;
			position: relative;
			@include bg_filter($bg_c4,0.6,before);
		}
	}
}

// company-table
.company-table {
	padding-top: clamp(3rem, 9vw, 9rem);
	margin-bottom: clamp(6rem, 10vw, 10rem);
	@include flex-column;
	row-gap: clamp(6rem, 9vw, 9rem);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
	}
	&__table {
		@include media-breakpoint-up(lg) {
			width: 65.55%;
		}
	}
}

// company-access
.company-access {
	&__inner {
		.txt {
			ul {
				text-align: left;
				& li {
					line-height: 1.4;
					padding-left: 1.5rem;
					position: relative;
					@include l-sp(0.03em);
					&::before {
						content: "●";
						color: $main_c;
						position: absolute;
						left: 0;
						top: 0.2em;
						font-size: 0.8em;
					}
					&:not(:last-child) {
						margin-bottom: 0.75rem;
					}
				}
			}
		}
	}
	&__tit {
		text-align: center;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		b {
			@include f-family(font01);
			color: $black;
			@include f-s_xxs(1.5, 20);
			@include f-w(700);
			position: relative;
			display: block;
			@include line-h(1);
			@include l-sp(0.075em);
			margin-bottom: 0.2em;
			@include media-breakpoint-up(xl) {
				@include f-size(44);
			}
		}
		small {
			@include f-family(font02);
			@include f-s_xxs(1, 10);
			@include f-w(500);
			color: $main_c;
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
	}
}

.access-map {
	position: relative;
	padding: 0;
	height: 0;
	overflow: hidden;
	background-color: $white;
	@include media-breakpoint-up(lg) {
		padding-bottom: 40%;
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 360px;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

// company-access
.company-access__flex {
	@include media-breakpoint-up(lg) {
		@include flex-between;
		& > * {
			width: 47.5%;
		}
	}
	@include media-breakpoint-down(md) {
		& > * {
			&:first-child {
				margin-bottom: 4rem;
			}
		}
	}
	.accessmap {
		@include media-breakpoint-up(lg) {
			padding-bottom: 66.66%;
		}
	}
}

.company-access__box {
	&__tit {
		margin-bottom: 0.75rem;
		@include f-s_xs(1, 2);
		@include f-w(500);
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}


/* about-video */
.about-video {
	@include m-a;
	width: 100%;
	max-width: 1100px;
	padding: clamp(2rem, 4vw, 4rem) 0;
	background-color: $bg_c3;
	&__tit {
		text-align: center;
		line-height: 1.4;
		margin-bottom: calc(1.5rem + 0.2em);
		position: relative;
		@include f-w(700);
		@include f-s_xs(1.14, 6);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	.txt {
		margin-bottom: 2rem;
		padding-left: 7%;
		padding-right: 7%;
	}
	&__video {
		@include m-a;
		width: 86%;
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}