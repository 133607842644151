@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	news
/--------------------------------------------------------------------*/

.news-intro {
	padding: clamp(1.5rem, 2vw, 2rem) 5%;
	margin-bottom: clamp(3rem, 6vw, 6rem);
	background: $p-gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(2rem, 3vw, 3rem) 8%;
		text-align: center;
	}
}

.news-single {
	&__inner {
		max-width: 1200px;
		@include m-a;
	}
}

// news-pdf
.news-pdf {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__list {
		@include flex-column;
		row-gap: clamp(0.5em, 1vw, 1em);
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			flex-wrap: wrap;
			column-gap: 2%;
		}
		& li {
			@include media-breakpoint-up(lg) {
				width: 49%;
			}
			a {
				padding: 1.25em 4% 1.25em 4.5em;
				@include transition;
				border: 1px solid $m-gray;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				@include line-h(1.4);
				color: $txt_c;
				@include dec-none;
				@include f-w(500);
				@include radius(999px);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.25em;
					top: 0.8em;
					font-size: 1.6em;
					color: $m-gray;
				}
				&:hover {
					background: $gray;
					border-color: $gray;
					color: $white;
				}
			}
		}
	}
}

